import React from "react";
import { Radio } from "antd";

const SysTeamCreateRadio = ({ disable, update, value, onChange }) => {
    return (
        <Radio.Group defaultValue={false} disabled={disable} checked={value} onChange={(e) => {
            onChange(e)
            update()
        }} >
            <Radio value={true}> True </Radio>
            <Radio value={false}> False </Radio>
        </Radio.Group>
    )
}

export default SysTeamCreateRadio;