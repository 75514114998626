import React, { useState, useEffect, useMemo } from 'react';
import { Select, Divider, Modal, Input, List, Checkbox } from 'antd';
import { CloseOutlined, EditOutlined, PlusOutlined, SearchOutlined, TagOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { api } from '@redux/api'
import { getMockTags } from './mock';

const AddTag = styled.div`
    padding: 4px 8px;
    margin-bottom: 4px;
    cursor: pointer;
    border-radius: 4px;
    &:hover {
        background-color: #f5f5f5;
    }
`

const Tag = styled.div`
    display: inline-block;
    padding: 4px 8px;
    margin: 4px 6px;
    background-color: #e6e6e6;
    border-radius: 4px;
    &:hover {
        background-color: #d9d9d9;
    }
`

const ListItem = styled.div`
    padding: 2px 6px;
    display: flex;
    align-items: center;
    cursor: pointer;
    &:hover {
        background-color: #f5f5f5;
    }
`


const TagList = ({ allTags, list, deleteTag }) => {
    return (
        <>
            {list.map((tag) => (
                <Tag key={tag}>
                    <TagOutlined style={{ marginRight: 4 }} />
                    {allTags.find(item => item.value === tag)?.label || tag}
                    <CloseOutlined style={{ marginLeft: 8, fontSize: 12, cursor: 'pointer' }} onClick={() => {
                        deleteTag(tag)
                    }} />
                </Tag>
            ))}
        </>
    )
}

const GlobalTag = ({ tableName, recordId, facility }) => {
    const [searchValue, setSearchValue] = useState('');
    const [searchValueInModal, setSearchValueInModal] = useState('');
    const [selectedTags, setSelectedTags] = useState([]);
    const [key, setKey] = useState(0);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [allTags, setAllTags] = useState([]);

    useEffect(() => {
        const fetchTags = async () => {
            setSelectedTags([]);
            try {
                const tags = await api.getCustomtags().then(res => {
                    if(res.status === 200) {
                        return res.data.results.map(item => ({ label: item.name, value: item.id }));
                    }
                    return []
                });
                setAllTags(tags);

                const fileTags = await api.getFileTags(tableName, recordId, facility).then(res => {
                    if(res.status === 200) {

                    }

                });
                // setSelectedTags(fileTags);

            } catch (error) {
                console.error("Error fetching tags:", error);
            }
        };

        fetchTags();
    }, []);


    const options = useMemo(() => {

        return allTags.filter(item => (item.label.toLowerCase().includes(searchValue.toLowerCase()) && !selectedTags.includes(item.value)))
    }, [allTags, searchValue, selectedTags]);

    const canCreateTag = searchValue.length > 0 && !allTags.some(item => item.label === searchValue);

    const handleCreateTag = () => {
        setAllTags([...allTags, { label: searchValue, value: searchValue }]);
        setSelectedTags([...selectedTags, searchValue]);
        setSearchValue('');
        setKey(key + 1);
    }

    const handleCreateModalTag = () => {
        setAllTags([...allTags, { label: searchValueInModal, value: searchValueInModal }]);
        setSelectedTags([...selectedTags, searchValueInModal]);
        setSearchValueInModal('');
        setKey(key + 1);
    }

    const deleteTag = (tag) => {
        const newTags = selectedTags.filter(item => item !== tag);
        setSelectedTags(newTags);
    }

    const filteredTags = useMemo(() => {
        if (!searchValueInModal) {
            return allTags;
        }
        return allTags.filter(item => item.label.toLowerCase().includes(searchValueInModal.toLowerCase()))
    }, [allTags, searchValueInModal]);

    const needCreateModalTag = searchValueInModal.length > 0 && !allTags.some(item => item.label === searchValueInModal);
    const onSelect = (value) => {

        setSelectedTags([...selectedTags, value]);
    }
    return (
        <>
            <Modal
                open={isEditModalOpen}
                title='Edit Tag'
                onCancel={() => setIsEditModalOpen(false)}
                width='90%'
                okText='Save'
                footer={null}
                size='small'
            >
                <div style={{ color: '#a6a6a6', marginBottom: 16 }}>Select the tags to add or the tags to remove from the notes.</div>
                <TagList list={selectedTags} deleteTag={deleteTag}></TagList>
                <Input value={searchValueInModal} onChange={(e) => setSearchValueInModal(e.target.value)} placeholder="Find tags or enter a new tag name..." suffix={<SearchOutlined />} allowClear style={{ marginBottom: 16 }} />
                {needCreateModalTag && <ListItem onClick={handleCreateModalTag}>
                    <PlusOutlined />
                    <div style={{ paddingLeft: 20 }}>Create This Tag `{searchValueInModal}`</div>
                </ListItem>
                }
                <List dataSource={filteredTags} renderItem={(item) => <ListItem>
                    <Checkbox checked={selectedTags.includes(item.value)} onChange={(e) => {
                        if (e.target.checked) {
                            setSelectedTags([...selectedTags, item.value]);
                        } else {
                            setSelectedTags(selectedTags.filter(tag => tag !== item.value));
                        }
                    }}>
                        <div style={{ paddingLeft: 10 }}>{item.label}</div>
                    </Checkbox>
                </ListItem>} />
            </Modal>
            <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                <TagOutlined style={{ color: '#4092ff' }} />
                <TagList allTags={allTags} list={selectedTags} deleteTag={deleteTag}></TagList>
                <Select key={key} bordered={false} style={{ width: 200 }} placeholder={'add tag ...'}
                    dropdownRender={(originNode) => {
                        return (
                            <>
                                {
                                    canCreateTag && <AddTag onClick={handleCreateTag}>
                                        <PlusOutlined style={{ marginRight: 8 }} />
                                        Create This Tag
                                    </AddTag>
                                }
                                {
                                    options.length > 0 && <>
                                        <Divider style={{ margin: '8px 0' }} />
                                        {originNode}
                                    </>
                                }
                            </>
                        )
                    }}
                    searchValue={searchValue}
                    onSearch={(value) => setSearchValue(value)}
                    showSearch={true}
                    options={options}
                    onSelect={onSelect}
                    suffixIcon={null}
                    allowClear={true}
                    notFoundContent={<div></div>}
                ></Select>
                <span onClick={() => setIsEditModalOpen(true)} style={{ flex: 1, textAlign: 'right', cursor: 'pointer', display: 'inline-block' }}><EditOutlined style={{color: '#4092ff'}} />
                    Edit Tag</span>
            </div>
        </>
    )
}

export default GlobalTag;
