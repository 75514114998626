import React, { useRef, useState, useEffect, useCallback, useMemo } from 'react';
import { Avatar, Button, Comment, Form, Input, List, Spin, Tooltip } from 'antd';
import moment from 'moment';
import { useSelector } from "react-redux";
import _ from "lodash";
import { api } from "@redux/api";
import notification2 from "@components/Notification2";

const { TextArea } = Input;

const CommentContent = React.memo(({ comment, userId, onDelete }) => {
    const deleteWorkorderComment = useCallback(() => {
        onDelete(comment.id);
    }, [comment.id, onDelete]);

    return (
        <Comment
            author={<a>{_.get(comment, 'createdby_name')}</a>}
            avatar={<Avatar src={_.get(comment, 'createdby_image')} />}
            content={_.get(comment, 'content')}
            datetime={[
                <div key="datetime" title={moment(_.get(comment, 'modified_date')).format('YYYY-MM-DD HH:mm:ss')}>
                    <span>{moment(_.get(comment, 'modified_date')).format('YYYY-MM-DD HH:mm:ss')}</span>
                </div>,
                <div key="delete" title="delete" className={comment.createdby === userId ? '' : 'hidden'}>
                    <a onClick={deleteWorkorderComment}><i className="ml-4 fas fa-trash-alt"></i></a>
                </div>
            ]}
        />
    );
});

const Editor = React.memo(({ onSubmit }) => {
    const [value, setValue] = useState('');

    const handleChange = useCallback((e) => {
        setValue(e.target.value);
    }, []);

    const handleSubmit = useCallback(() => {
        onSubmit(value);
        setValue('');
    }, [value, onSubmit]);

    return (
        <Comment
            content={
                <div>
                    <TextArea rows={3} onChange={handleChange} value={value} />
                    <Button htmlType="submit" onClick={handleSubmit} type="primary" className={'mt-2'}>
                        Add Comment
                    </Button>
                </div>
            }
        />
    );
});

export const WorkorderComment = React.memo(({ id, boxHeight }) => {
    const messagesEndRef = useRef(null);
    const [comments, setComments] = useState([]);
    const [loading, setLoading] = useState(false);

    const userId = useSelector((state) => state.Auth.userId);

    const getWorkorderComments = useCallback((workorderId) => {
        setLoading(true);
        return api.getWorkorderComment(workorderId)
            .then(items => {
                if (_.get(items, 'status') === 200) {
                    let data = items?.data.results;
                    if (data) {
                        data.sort((a, b) => new Date(b.created_date) - new Date(a.created_date));
                    }
                    setComments(data ? data : []);
                } else {
                    notification2['error']({
                        message: 'error',
                        description: _.get(items, 'response.statusText'),
                    });
                }
            })
            .finally(() => setLoading(false));
    }, []);

    useEffect(() => {
        if (id) {
            getWorkorderComments(id);
        }
    }, [id, getWorkorderComments]);

    const deleteWorkorderComment = useCallback((commentId) => {
        setLoading(true);
        api.deleteWorkorderComment(commentId)
            .then(() => getWorkorderComments(id))
            .finally(() => setLoading(false));
    }, [id, getWorkorderComments]);

    const handleSubmit = useCallback((content) => {
        setLoading(true);
        api.createWorkorderComment({ content, workorder: id })
            .then(response => getWorkorderComments(response.data.workorder))
            .catch(error => console.error(error))
            .finally(() => setLoading(false));
    }, [id, getWorkorderComments]);

    const memoizedComments = useMemo(() => comments, [comments]);

    return (
        <Spin spinning={loading}>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                height: boxHeight ? boxHeight : '70vh',
            }}>
                <div style={{ flex: 1, overflowY: 'auto', padding: '10px' }} className="comment-list">
                    <List
                        ref={messagesEndRef}
                        style={{ overflowY: 'auto' }}
                        dataSource={memoizedComments}
                        header={`${memoizedComments.length} ${memoizedComments.length > 1 ? 'replies' : 'reply'}`}
                        itemLayout="horizontal"
                        renderItem={comment => <CommentContent comment={comment} userId={userId} onDelete={deleteWorkorderComment} />}
                    />
                    <div ref={messagesEndRef} />
                </div>
                <Editor onSubmit={handleSubmit} />
            </div>
        </Spin>
    );
});
