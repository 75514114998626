import { Radio } from "antd";
import Input from "antd/es/input";
import React from "react";
import TableNameLookup from "./components/TableNameLookup";
import FacilityLookup from "./components/FacilityLookUp";
import SysTeamCreateRadio from "./components/SysTeamCreateRadio";

export const TagManageFormFields = (formRef, role, update) => {
    const systemCreateField = role === 1 ? [

        {
            title: 'Is System Create',
            name: 'is_sys_created',
            order: 2,
            visible: true,
            length: "w-full",
            type: 'checkbox',
            render: (disable) => <SysTeamCreateRadio defaultValue={false} disabled={disable} update={update} />,
            required: false,
        }] : []

    const facilityField = role === 1 && !formRef?.getFieldValue('is_sys_created') ? [
        {
            title: 'Facility',
            name: 'facility',
            visible: false,
            type: 'text',
            required: false,
        },
        {
            title: 'Facility',
            name: 'facility_name',
            visible: true,
            length: "w-full",
            type: 'Lookup_text',
            required: false,
            render: (value) => {
                return <FacilityLookup value={value} formRef={formRef} />
            }
        }] : []

    return [
        {
            title: 'key',
            name: 'id',
            order: 0,
            visible: false,
            length: 0,
            type: 'text',
        },
        {
            title: 'Name',
            name: 'name',
            order: 1,
            visible: true,
            length: "w-full",
            type: 'text',
            required: true,
            render: <Input />
        },
        ...systemCreateField,
        ...facilityField,
        {
            title: 'TableName',
            name: 'table_name',
            visible: false,
            type: 'text',
            required: false,
        },
        {
            title: 'TableName',
            name: 'table_name',
            order: 1,
            visible: true,
            length: "w-full",
            type: 'Lookup_text',
            required: false,
            render: (value) => {
                return <TableNameLookup value={value} formRef={formRef} />
            }
        },
        {
            title: 'Type',
            name: 'type',
            order: 5,
            visible: true,
            length: "w-full",
            type: 'text',
            render: <Input />
        },
        // {
        //     title: 'Fields',
        //     name: 'fields',
        //     order: 8,
        //     visible: true,
        //     length: "w-full",
        //     type: 'customJSON',
        //     render: (value) => {
        //         return (<>{<FunctionalFormFieldsEditor value={value} formRef={formRef} />}</>)
        //     }
        // },

    ]
}
