
export const getCompanyId = (company = '') => {
    return company.split('company_').join('') * 1;
}

const mergeCompanyId = (companyId) => {
    return 'company_' + companyId;
}

// 将所有facility转换为树结构

export function convertToTreeStructure(allFancility, user) {
    const tree = {};

    allFancility.forEach(facility => {
        const { company, company_id, id, name } = facility.record;
        if(Array.isArray(user) && !user.includes(id)) {
            return;
        }

        const companyId = mergeCompanyId(company_id);

        if (!tree[companyId]) {
            tree[companyId] = {
                label: company,
                value: companyId,
                children: []
            };
        }

        // 添加子节点
        tree[companyId].children.push({
            label: name,
            value: id
        });
    });

    // 将对象转换为数组
    return Object.values(tree);
}


