import React, { Component, useMemo } from 'react';
import AutoComplete from "antd/es/auto-complete";
import Input from "antd/es/input";
import { CaretDownOutlined } from "@ant-design/icons";
import { api } from "@redux/api";
import { jsforceResponse } from "@config/httpService";
import _ from "lodash";
import { useSelector } from 'react-redux';
import { convertToTreeStructure, getCompanyId } from './CompanyFacilityPicker/convertToTreeStructure';

class CompanyLookupClass extends Component {
    constructor(props) {
        super(props);
        this.state = {
            options: [],
            value: this.props.value || null,
        };
        this.debouncedSearch = _.debounce(this.performSearch, 300);
        this.throttledSetState = _.throttle(this.setState.bind(this), 1000);
    }
    async componentDidMount() {
        this.setState({ value: this.props.record });
    }
    fetchCompanies = async (query = '') => {
        try {
            const result = await api.filterCompany('name='+query);
            const res = jsforceResponse(result, '', false, true);
            const company_items = _.get(res, 'data.results', []);

            const options = company_items.map(item => ({
                id: item.id,
                label: item.name,
                value: item.name,
                record: { id: item.id, value: item.name },
            }));
            this.throttledSetState({ options });
        } catch (error) {
            console.error('Error fetching companies:', error);
        }
    }

    handleFocus = () => {
        this.fetchCompanies();
    }

    handleSearch = (searchTerm) => {
        this.setState({ value: searchTerm });
        this.debouncedSearch(searchTerm);
    }

    performSearch = async (searchTerm) => {
        await this.fetchCompanies(searchTerm);
    }

    handleSelect = (value, option) => {
        this.setState({ value: option.label });
        this.props.formRef.setFieldsValue({
            company: option.id
        });
        const role = this.props.formRef.getFieldsValue().role;
        if(role) {
            this.setFacilities(role, option.id);
        }
    }

    setFacilities = (role, company) => {
        const { formRef, allFacilities, companyTreeOptions } = this.props;
        if (formRef) {
            let facilities = [];
            let companies = [];
            if(role === 1) {
                facilities = allFacilities.map(facility => facility.record.id);
                companies = companyTreeOptions.map(company => getCompanyId(company.value));
            }else if(role === 3) {
                facilities = allFacilities.filter(facility => facility.record.company_id === company).map(facility => facility.record.id);
                companies = [company];
            }

            if (formRef.setFieldsValue) {
                formRef.setFieldsValue({ role, user: facilities, companies });
            } else {
                formRef.current.setFieldsValue({ role, user: facilities, companies });

            }
        }
    }

    render() {
        return (
            <AutoComplete
                style={{ width: '100%' }}
                options={this.state.options}
                onSelect={this.handleSelect}
                onSearch={this.handleSearch}
                value={this.state.value}
                dropdownMatchSelectWidth={false}
                onFocus={this.handleFocus}
            >
                <Input
                    size="medium"
                    allowClear={true}
                    autosize={true}
                    autoComplete="off"
                    placeholder={'search a company'}
                    prefix={<div style={{color: 'red'}}>*</div>}
                    suffix={<CaretDownOutlined/>}
                />
            </AutoComplete>
        );
    }
}

const CompanyLookup = (props) => {

    const allFacilities = useSelector(state => state.Facility.data);

    const companyTreeOptions = useMemo(() => convertToTreeStructure(allFacilities), [allFacilities]);

    return <CompanyLookupClass companyTreeOptions={companyTreeOptions} allFacilities={allFacilities} {...props} />
}

export default CompanyLookup;
