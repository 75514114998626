import React, {Component} from 'react';
import {Button, Checkbox, DatePicker, Divider, Form, PageHeader, Popconfirm, Spin, Table, Tabs, Typography} from 'antd';
import {
    CloseCircleOutlined,
    CopyOutlined,
    DatabaseOutlined,
    DeleteOutlined,
    FilePdfOutlined,
    SaveOutlined
} from '@ant-design/icons';
import _ from "lodash";
import moment from "moment";
import TagInput from "@components/TagInput/TagInput.js";
import {connect} from "react-redux";

import FileManager from "@components/FileManager/FileManager";
import notification2 from "@components/Notification2";
import PermissionControl from "@components/Permission/Permission";
import {DetailWrapper} from "@components/Table/DetailWrapper";
import {
    FunctionalTestingFormFields
} from "@containers/facility/Equipment/FunctionalTesting/FunctionalTestingFormFields";
import {api} from "@redux/api";
import styled from "styled-components";
import FunctionTestingClone from "@containers/facility/Equipment/FunctionalTesting/FunctionTestingClone";
import { FileManagement } from '@/components/FileManagement';
const {TabPane} = Tabs;
const {Paragraph} = Typography;
const FunctionalTestingWrapper = styled.div`
    td.ant-table-cell {
        max-width: 25rem;
    }
`
class FunctionalTestingDetail extends Component {
    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.state = {
            fields: [],
            fileList: [],
            tags: [
                {id: 1, content: 'Record'}
            ],
            visible: false,
            random: 0,
            iframe_key: 0,
            isLoading: false,
            formfields: [],
            readOnly: true,
            data: [],
            previewImage: false,
            selectedTypeOption: null,
            isCloneOpen: false,
        };
    }

   async componentDidMount() {

       const data = await this.handleImage(this.props.data)
        const formfields = FunctionalTestingFormFields(this.formRef.current, this.props.selectedTypeOption, this.props.selectedRecord, this.props.selectedTemplateType);
        const fields = this.handleFieldsMapping(formfields, data, this.props.isNewRecords);
        let readOnly = true;
        if (this.props.isClone) {
            readOnly = false
        }
        this.setState({
            formfields: formfields,
            fields: fields,
            data,
            readOnly
        })
    }

    async componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.data !== this.props.data) {
            const data = await this.handleImage(nextProps.data)
            const formfields = FunctionalTestingFormFields(this.formRef.current, nextProps.selectedTypeOption, this.props.selectedRecord, nextProps.selectedTemplateType);
            const fields = this.handleFieldsMapping(formfields, data, nextProps.isNewRecords)
            this.setState({
                data: data,
                fields: fields
            })
        }
    }

    handleImage = async (data) => {
        console.log('handleImage')
        if (!data?.value){
            return data
        }
        const values = data?.value;
        const updatedValues = await Promise.all(values.map(async (e) => {
            let image = e?.Image;
            if (image) {
                let image_url_short = image?.url?.split('https://facilitykpiclientfile.s3.us-east-2.amazonaws.com/facilitykpiclientfile/')[1];
                try {
                    const imagePreview = await api.generate_url(image_url_short, true);
                    e['ImagePreview'] = imagePreview.data.url; // Assuming the URL is in the response's data.url
                    return e; // Return the updated entry
                } catch (error) {
                    console.error(error);
                    return e; // Return the entry as is in case of an error
                }
            } else {
                return e; // Return the entry as is if it doesn't have an image
            }
        }));

        data.value = updatedValues;
        console.log('values', data)
        return data;
    }

    handleFieldsMapping= (formfields, data, isNewRecords) => {

        let fields = [];
        if (data && formfields && !isNewRecords) {

            formfields.map(e => {
                    let field = e
                    if (e.type === 'Date') {
                        field['value'] = moment(data[e.name]).format('YYYY-MM-DD');
                    } else if (e.type === 'Lookup') {
                        field['value'] = _.get(data[e.name + '_name'], 'name');
                    } else if (e.type === 'Lookup_text') {
                        field['value'] = data[e.name];
                    } else if (e.type === 'Tag' || e.type === 'JSON') {
                        field['value'] = this.parseJson(data[e.name]);
                    } else {
                        field['value'] = data[e.name];
                    }

                    fields.push(field)
                    return fields
                }
            )
        } else {
            formfields && formfields.map(e => {
                    let field = e
                    delete field['value']

                    fields.push(field)
                    return fields
                }
            )
        }
        return fields
    }

    handleClose = (e) => {
        this.props.onClose()

    }

    handleSubmit = async (data) => {

        this.setState({
            isLoading: true
        })

        if (!this.props.isLookupCreateNew && !this.props.isClone) {
            let result = await this.props.handOnlineSave(data.id, data)

            this.setState({
                disabled: true,
                isLoading: false
            })
            if (result && result?.status >= 200 && result?.status < 300) {
                // this.props.onClose()
                notification2['success']({
                    message: 'Success',
                    description: 'Record was successfully saved', placement: 'topLeft'
                });
            }
        } else {
            let result = await this.props.createRecords(data)
            this.setState({
                disabled: true,
                isLoading: false,
                readOnly: true
            })
            console.log('result', result)
            if (result && result?.status >= 200 && result?.status < 300) {
                // this.props.onClose()
                notification2['success']({
                    message: 'Success',
                    description: 'Record was successfully saved', placement: 'topLeft'
                });
                this.props.onClose()
            }
        }
    }

    handleDelete = (e) => {
        let id = _.get(this.props.data, 'id')
        this.props.handleDelete(id)
        this.props.onClose()
    }

    handleClone = (e) => {
        this.setState({
            isCloneOpen: true,
        })
    }

    parseJson = (data) => {
        try {
            return JSON.parse(data);
        } catch (e) {
            return []
        }
    }

    onTabChange = (key) => {

        this.setState({
            selectedTab: key,
            random: this.state.random + 1
        })
    }

    showPopconfirm = (e) => {
        this.setState({
            visible: true
        })
    }
    handleConfirmCancel = (e) => {
        this.setState({
            visible: false
        })

    }
    openEditMode = (e) => {
        this.setState({
            readOnly: !e
        })
    }
    renderOperationButton = (e) => {
        return [
            <>{this.state.readOnly && this.props.data&&<PermissionControl allowedPermissions={'3:' + this.props.columnProps?.name}>
                <Popconfirm
                    title="Do you want to delete this record"
                    visible={this.state.visible}
                    onConfirm={this.handleDelete}
                    onCancel={() => this.handleConfirmCancel()}
                >
                    {this.state.readOnly && <Button key="2" danger onClick={() => this.showPopconfirm()}
                                                    icon={<DeleteOutlined/>}
                                                    disabled={this.props.isNewRecords}>Delete</Button>}
                </Popconfirm>
            </PermissionControl>}</>
            ,


            <div className={'flex mx-5 gap-3'}>
                <PermissionControl allowedPermissions={'2:' + this.props.columnProps?.name}>
                    <>{this.state.readOnly && <Button type='primary' warning key="3" form={this.props.formKey + 'form'}
                                                      onClick={this.handleClone} icon={<CopyOutlined/>}
                                                      disabled={this.props.isNewRecords}>Duplicate</Button>}</>
                </PermissionControl>

                <PermissionControl allowedPermissions={'2:' + this.props.columnProps?.name}>
                    {(!this.state.readOnly || !this.props.data) &&
                        <Button type='primary' key="submit" form={this.props.formKey + 'form'} htmlType="submit"
                                icon={<SaveOutlined/>}
                        >Save</Button>}
                    {this.state.readOnly && this.props.data ?
                        <Button type='primary' key="openEditMode" form={this.props.formKey + 'form'}
                                onClick={() => this.openEditMode(true)}
                                icon={<i className="fa-regular fa-pen-to-square pr-2"></i>}
                        >Edit</Button> :
                        (this.props.data &&
                            <Button type='primary' key="closeEditMode" form={this.props.formKey + 'form'}
                                    onClick={() => this.openEditMode(false)}
                                    icon={<i className="fa-regular fa-circle-xmark pr-2"></i>}
                            >Close Edit</Button>)}

                </PermissionControl>
            </div>,

            <>{this.state.readOnly && <Button ghost style={{backgroundColor: 'rgb(133 148 165)'}} key="1"
                                              onClick={this.handleClose}
                                              icon={<CloseCircleOutlined/>}>Close</Button>}</>,
        ]
    }
    setPreviewImage = (e) => {
        this.setState({
            previewImage: e
        })
    }
    renderCustomJSON = (data) => {
        console.log('renderCustomJSON: ', data)
        if (data?.length === 0) {
            return null
        }
        let columns = []
        const firstRowKeys = ['type', 'name',  'description',  'SetPoint', 'BMSValue', 'MeasuredValue','Acceptable', 'Comment',]
        firstRowKeys.forEach((column) => {
            if (column !== 'ImagePreview') {
                columns.push({
                    title: column,
                    dataIndex: column,
                    key: column,
                    className: column === 'id' ? 'hidden' : ''
                })
            } else {

                columns.push({
                    title: column,
                    dataIndex: column,
                    key: column,
                    className: column === 'id' ? 'hidden' : '',
                    render: (text, record) => {
                        console.log('ImagePreview:', text, record?.Image)
                        return <img src={text} width={'20px'} onClick={() => this.setPreviewImage(text)}/>
                    }
                })
            }
        })

        return (

            <Table columns={columns} className={'w-full col-span-4 my-5'} dataSource={data} pagination={false}
                   rowKey="id"/>

        )
    }

    render() {

        return (
            <DetailWrapper>
                <FunctionalTestingWrapper>
                <Spin spinning={this.state.isLoading}>
                    <Tabs defaultActiveKey="1" onChange={this.onTabChange}>
                        <TabPane tab={<span> <DatabaseOutlined/>Detail </span>} key="1">
                            <div>

                                <div className={'slds-m-around_x-small'}>
                                    <PageHeader
                                        title={this.state.data?.name}
                                        className="site-page-header justify-center"
                                        subTitle={''}
                                        extra={this.renderOperationButton()}

                                    />
                                </div>

                                <div className={'slds-m-around_x-small'}>
                                    <div className={'flex p-8 '}>
                                        <div className={'w-full'} >
                                            <Form
                                                key={'FunctionTestingDetail'}
                                                onFinish={this.handleSubmit}
                                                layout="horizontal"
                                                labelCol={{span: 8}}
                                                fields={this.state.fields}
                                                ref={this.formRef}
                                                className={this.props.formClassName ? this.props.formClassName : 'detailForm'}
                                                id={this.props.formKey + 'form'}
                                            >

                                                {this.state.fields.map(e => {
                                                        return (
                                                            this.state.readOnly && this.state.data ?
                                                                <>
                                                                    {
                                                                        e.type === 'File' ?
                                                                            <Form.Item label={e.title} name={e.name}
                                                                                       key={e.name}
                                                                                       className={e.length}
                                                                                       hidden={!e.visible}
                                                                                       getValueFromEvent={({file}) => file.originFileObj}>
                                                                                <img src={e.value} width={'20px'}/>
                                                                            </Form.Item> :
                                                                            e.type === 'checkbox' ?
                                                                                <Form.Item label={e.title} name={e.name}
                                                                                           key={e.name}
                                                                                           className={e.length}
                                                                                           hidden={!e.visible}>
                                                                                    <Checkbox checked={e.value}
                                                                                              disabled={true}/>
                                                                                </Form.Item> :
                                                                                e.type === 'radio' ?
                                                                                    <Form.Item label={e.title}
                                                                                               key={e.name}
                                                                                               name={e.name}
                                                                                               className={e.length}
                                                                                               hidden={!e.visible}>
                                                                                        {e.render}
                                                                                    </Form.Item> :
                                                                                    e.type === 'custom_text' ?
                                                                                        <Form.Item label={e.title}
                                                                                                   name={e.name}
                                                                                                   key={e.name}
                                                                                                   className={e.length}
                                                                                                   hidden={!e.visible}>
                                                                                            {e.render(this.state.fields)}
                                                                                        </Form.Item> :
                                                                                        e.type === 'customJSON' ?
                                                                                            (this.renderCustomJSON(e.value)) :
                                                                                            // e.render(e.value):
                                                                                            (<Form.Item label={e.title}
                                                                                                        name={e.name}
                                                                                                        key={e.name}
                                                                                                        className={e.length}
                                                                                                        hidden={!e.visible}>
                                                                                                <span>{e.value}</span>
                                                                                            </Form.Item>)
                                                                    }
                                                                </>
                                                                :
                                                                <>
                                                                    {e.title === 'Separator' ? <Divider/> :
                                                                        (e.type === 'date' ?
                                                                                <Form.Item label={e.title}
                                                                                           name={e.name}
                                                                                           className={e.length}
                                                                                           valuePropName={moment(e.value)}
                                                                                           key={e.name}
                                                                                           rules={[
                                                                                               {
                                                                                                   required: e.required,
                                                                                                   message: 'this field is required',
                                                                                               },
                                                                                           ]}
                                                                                           hidden={!e.visible}>
                                                                                    <DatePicker
                                                                                        defaultValue={e.value ? e.value : null}/>
                                                                                </Form.Item>

                                                                                :
                                                                                <Form.Item label={e.title}
                                                                                           name={e.name}
                                                                                           className={e.length}
                                                                                           tooltip={e.tooltip}
                                                                                           key={e.name}
                                                                                           rules={[
                                                                                               {
                                                                                                   required: e.required,
                                                                                                   message: 'this field is required',
                                                                                               },
                                                                                           ]}
                                                                                           hidden={!e.visible}>

                                                                                    {(e.title === 'Tag' ?
                                                                                            <TagInput
                                                                                                handleTag={this.handleTag}
                                                                                                value={e.value}/> :
                                                                                            ((e.type === 'Lookup_text' || e.type === 'Lookup' || e.type === 'customJSON') ? e.render(e.value) :
                                                                                                (e.dependent ? e.render(this.state.fields) : e.render))
                                                                                    )}


                                                                                </Form.Item>

                                                                        )}
                                                                    {e.datalist}
                                                                </>
                                                        )


                                                    }
                                                )}


                                            </Form>
                                        </div>

                                    </div>


                                </div>


                            </div>
                        </TabPane>

                        {this.props.isAttachments ?
                            <TabPane tab={<span> <FilePdfOutlined/>Attachments </span>} key="3">
                                {_.get(this.state.data, 'id', null) ? <>
                                    <FileManagement
                                    tableName={_.get(this.props.columnProps, 'name', [])}
                                    recordId={_.get(this.state.data, 'id', null)}
                                    equipmentId={_.get(this.state.data, 'equipment', null)}
                                />

                                </> : ''}
                            </TabPane> : ''}

                        {/*{*/}
                        {/*    this.props.isAdditionalForm ?*/}
                        {/*        <TabPane tab={<span> <FileProtectOutlined/>Functional Testing Form </span>} key="4">*/}
                        {/*            {this.props.renderAdditionalForm()}*/}
                        {/*        </TabPane>*/}
                        {/*        : ''*/}
                        {/*}*/}
                    </Tabs>

                    {this.state.previewImage && (
                        <div
                            style={{
                                position: 'fixed',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                zIndex: 1000, // Ensure it's above other content
                            }}
                            onClick={() => this.setPreviewImage(null)} // Close on backdrop click
                        >
                            <img src={this.state.previewImage} style={{ maxHeight: '80%', maxWidth: '80%' }} alt="Preview" />
                        </div>
                    )}

                </Spin>
                    {this.state.isCloneOpen? <FunctionTestingClone
                        isCloneOpen={this.state.isCloneOpen}
                        toggleCloneDrawer={(e) => this.setState({isCloneOpen: e})}
                        selectedTemplateType={this.state.selectedTemplateType}
                        selectedTypeOption={this.state.selectedTypeOption}
                        columnProps={this.props.columnProps}
                        data={this.props.data}
                        createRecords={this.props.createRecords}
                    /> : ''}
                </FunctionalTestingWrapper>
            </DetailWrapper>
        );
    }
}

const mapStateToProps = state => {
    return {}
}

const mapDispatchToProps = dispatch => {
    return {}
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(FunctionalTestingDetail);
