import React, { Component } from 'react';
import EditableTable from "@components/Table/EditableTable";
import { connect } from "react-redux";
import { api } from "@redux/api";
import { TagManageColumns, TagManageProps } from "./TagManageColumns";
import { TagManageFormFields } from "./TagManageFields";


class TagManage extends Component {
    constructor(props) {
        super(props);
        this.tableRef = React.createRef();
        this.state = {
            data: [],
            name: '',
            isLoading: false,
            query: null,
            tableRef: null,
            update: 0
        }
    }

    handleCreateTags = ({ table_name, ...body}) => {

        let newBody = { ...body };
        delete newBody.id
        if (newBody.is_sys_created) {
            newBody.facility = null
        } else {
            newBody.facility = this.props.facility
        }

        return api.createCustomtag(table_name, newBody).then(
            response => {
                return response.data
            }
        ).catch(error => (
            error
        ));
    }

    handleDeleteTags = (id) => {
        return api.deleteCustomtag(id).then(
            response => {
                return response.data
            }
        ).catch(error => (
            error
        ));
    }

    handleBulkDelete = (body) => {

        return Promise.all(body.map(item => {
            return api.deleteCustomtag(item.id).then(
                response => {
                    return response.data
                }
            ).catch(error => (
                error
            ));
        }));
    }

    handOnlineSave = (key, row) => {
        return api.editCustomtag(key, row).then(
            response => {

                return response
            }
        ).catch(error => (
            error
        ));
    }


    handleFilter = (query) => {

        this.setState({
            isLoading: true,
        });

        return api.getCustomtags(query).then(
            response => {
                let dataSource = response.data.results
                return {
                    data: dataSource,
                    success: true,
                    total: response.data.count
                }
            }
        ).catch(error => (
            error
        ));
    }

    getRecordTags = (data) => {

        this.setState({
            isLoading: false,
            data
        })

    }

    refresh = (needRequest) => {
        return this.tableRef?.current?.refresh(needRequest);
    };

    renderSearches = () => {
    }
    gettableRef = (tableRef) => {
        console.log('tableRef', tableRef)
        this.setState({
            tableRef
        })
    }

    handleUpdate = () => {
        this.setState({
            update: this.state.update + 1
        })
    }

    render() {
        return (

            <div>
                <EditableTable
                    ref={this.tableRef}
                    columns={TagManageColumns}
                    data={this.state.data}
                    columnProps={TagManageProps}
                    formFields={TagManageFormFields(this.state.tableRef, this.props.role, this.handleUpdate)}
                    getRecord={this.getRecordTags}
                    createRecords={this.handleCreateTags}
                    deleteRecord={this.handleDeleteTags}
                    bulkInsert={this.handleBulkInsert}
                    bulkDelete={this.handleBulkDelete}
                    handOnlineSave={this.handOnlineSave}
                    handleFilter={this.handleFilter}
                    postComment={this.handlePostComment}
                    renderFilter={this.renderFilter}
                    hide_search={true}
                    isRowClickable={false}
                    isRelated={false}
                    isAttachments={false}
                    setFilters={this.setFilters}
                    filterDescription={this.state.filterDescription}
                    getFormRef={this.gettableRef}
                    hide_imports={true}
                    formClassName={'flex-form  '}
                />
            </div>

        );
    }
}

const mapStateToProps = state => {
    return {
        facility: state.Facility.selected.Id,
        userId: state.Auth?.userId,
        company: state.Facility?.selected?.record?.company_id,
        role: Number(Object.keys(state?.Auth?.role || {})[0])
    }
}
const mapDispatchToProps = dispatch => {
    return {}
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TagManage);
