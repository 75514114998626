import React, {Component} from 'react';
import EditableTable from "@components/Table/EditableTable";
import {connect} from "react-redux";
import {api} from "@redux/api";
import {
    FunctionalTestingColumns,
    functiontestingProps
} from "@containers/facility/Equipment/FunctionalTesting/FunctionalTestingColumns";
import {
    FunctionalTestingFormFields
} from "@containers/facility/Equipment/FunctionalTesting/FunctionalTestingFormFields";
import {Drawer, Select} from "antd";
import SearchLookup from "../../../../components/SearchLookup";
import FunctionalTestingDetail from "@containers/facility/Equipment/FunctionalTesting/FunctionalTestingDetail";
import FunctionTestingEditableTable
    from "@containers/facility/Equipment/FunctionalTesting/FunctionTestingEditableTable";

const {Option} = Select;

class FunctionalTesting extends Component {
    constructor(props) {
        super(props);
        this.tableRef = React.createRef();
        this.state = {
            data: [],
            name: '',
            isLoading: false,
            query: null,
            tableRef: null,
            type_options: [],
            selectedTypeOption: null,
            isOpen: false,
            selected_id: null,
            selectedTemplateType: null,
        }
    }

    handleCreateFunctionalTesting = (body) => {
        console.log('create')
        const equipment_id = this.props.selectedRecord?.id?this.props.selectedRecord?.id:body.equipment__id;
        body['equipment'] = equipment_id;
        return api.createFunctionalTestingView(body).then(
            response => {
                // console.log('create', response);
                this.refresh(false)
                return response.data
            }
        ).catch(error => (
            error
        ));
    }

    handleDeleteFunctionalTesting = (id) => {
        return api.deleteFunctionalTestingView(id).then(
            response => {
                this.refresh(false)
                return response.data
            }
        ).catch(error => (
            error
        ));
    }

    handleBulkDelete = (body) => {
        return api.bulkFunctionalTestingView(body).then(
            response => {
                this.refresh(false)
                return response.data
            }
        ).catch(error => (
            error
        ));
    }

    handOnlineSave = (key, row) => {
        row['equipment'] = this.props.selectedRecord?.id?this.props.selectedRecord?.id:row.equipment__id;

        return api.editFunctionalTestingView(key, row).then(
            response => {
                // this.handleDetailDrawer(false, null)
                this.refresh(false)
                return response
            }
        ).catch(error => (
            error
        ));
    }


    handleFilter = (query) => {

        this.setState({
            isLoading: true,
        });
        console.log('FunctionalTesting::', this.props.selectedRecord)
        let query_new = query +
            (this.state.name ? ('&search=' + (this.state.name)) : '') +
            (this.props.selectedRecord?.id ? ('&equipment_id=' + (this.props.selectedRecord?.id)) : '')

        return api.filterFunctionalTestingView(query_new).then(
            response => {
                let dataSource = response.data.results
                return {
                    data: dataSource,
                    total: response.data.count,
                    success: true,
                }
            }
        ).catch(error => (
            error
        ));
    }




    getFunctionalTesting = (data) => {

        this.setState({
            isLoading: false,
            data
        })

    }

    refresh = (needRequest) => {
        return this.tableRef?.current?.refresh(needRequest);
    };

    async componentDidMount() {
        let type_data = [], type_options = []
        let equipment_type = this.props.selectedRecord?.category
        const response = await api.filterFunctionalFormView('&type=' + equipment_type);
        type_data = response.data.results;

        type_options.push({
            key: '',
            value: '',
            label: 'Blank Template(Default)',
            record: ''
        })

        for (let i = 0; i < type_data?.length; i++) {
            type_options.push({
                key: type_data[i].id,
                value: type_data[i].name,
                label: type_data[i].name,
                record: type_data[i]
            })
        }

        this.setState({
            type_options,
        })
    }
    handleLookupFilter = (e) => {

        let query_new = (this.state.equipment_keyword ? ('name=' + this.state.equipment_keyword) : '') +
            '&limit=15';

        return api
            .filterEquipment(query_new)
            .then((response) => {
                let dataSource = response.data.results;
                return {
                    data: dataSource,
                    success: true,
                    total: response.data.count,
                };
            })
            .catch((error) => {
                return error;
            });
    }
    handleSearchSelect2 = (e, type) => {
        this.setState({
            [type]: e,
            limit: null
        }, function () {
            this.refresh(false);
        })
    }

    handleSearch = (e, type) => {
        this.setState({
            [type + '_keyword']: e
        });
        return this.handleLookupFilter();
    }
    handleSearchSelect = (e, record) => {
        let data = record.record;
        this.setState({
            selectedTypeOption: data?.fields,
            selectedTemplateType: data
        })
    }

    renderSearches = () => {
        return (
            <div className={'mb-4 '}>
                <div className={'mb-4 flex flex-wrap'}>
                    <SearchLookup
                        name={'name'}
                        defaultValue={this.state.name}
                        style={{width: '20rem', paddingRight: '1rem'}}
                        SelectedLookup_CommonRequest={(e) => this.handleSearchSelect2(e, 'name')}
                        Lookup_CommonupRequest={(e) => this.handleSearch(e, 'name')}
                    />
                </div>
            </div>
        )
    }
    gettableRef = (tableRef) => {
        console.log('tableRef', tableRef)
        this.setState({
            tableRef
        })
    }

    renderDrawer = (isOpen, selected_id) => {

        const drawerKey = 'FunctionalTesting_drawer'+selected_id;
        return <Drawer
            key={drawerKey}
            width={'100%'}
            placement="right"
            closable={false}
            onClose={() => this.handleDetailDrawer(false, null)}
            visible={isOpen}
            destroyOnClose={true}
            autoFocus={true}
        >
            <div>
                {isOpen ?
                    <FunctionalTestingDetail
                        key={'FunctionalTesting_detail'}
                        formKey={'FunctionalTesting_detail'}
                        columnProps={functiontestingProps}
                        formClassName={'flex-form grid grid-cols-4 '}
                        isNewRecords={false}
                        selectedTemplateType={this.state.selectedTemplateType}

                        handOnlineSave={this.handOnlineSave}
                        createRecords={this.handleCreateFunctionalTesting}
                        handleSaveComment={this.handleSaveComment}
                        onClose={() => {this.handleDetailDrawer(false, null)}}
                        handleDelete={this.handleDeleteFunctionalTesting}
                        isAttachments={true}
                        formRefCallback={this.formRefCallback}
                        data={this.state.data.filter(e => e.id === selected_id)[0]}/> : ''}
            </div>
        </Drawer>
    }

    handleDetailDrawer = (e, selected_id) => {
        this.setState({
            isOpen: e,
            selected_id: selected_id
        })
    }
    render() {

        return (

            <div>
                <FunctionTestingEditableTable
                    ref={this.tableRef}
                    columns={FunctionalTestingColumns(
                        this.handleDetailDrawer
                    )}
                    data={this.state.data}
                    columnProps={functiontestingProps}

                    getRecord={this.getFunctionalTesting}
                    createRecords={this.handleCreateFunctionalTesting}
                    deleteRecord={this.handleDeleteFunctionalTesting}
                    bulkInsert={this.handleBulkInsert}
                    bulkDelete={this.handleBulkDelete}
                    handOnlineSave={this.handOnlineSave}
                    handleFilter={this.handleFilter}
                    postComment={this.handlePostComment}
                    renderFilter={this.renderSearches}
                    hide_search={true}
                    isRowClickable={false}
                    isRelated={false}
                    isAttachments={false}
                    setFilters={this.setFilters}
                    filterDescription={this.state.filterDescription}
                    getFormRef={this.gettableRef}
                    hide_imports={true}
                    formClassName={'flex-form grid grid-cols-4 '}
                    handleDetailDrawer={this.handleDetailDrawer}
                    selectedRecord={this.props.selectedRecord}
                />

                {this.renderDrawer(this.state.isOpen, this.state.selected_id)}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {}
}
const mapDispatchToProps = dispatch => {
    return {

    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FunctionalTesting);
