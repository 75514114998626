import React, { useState } from 'react';
import { Select } from 'antd'
import _ from 'lodash'

import AutoComplete from "antd/es/auto-complete";
import Input from "antd/es/input";
import { jsforceResponse } from "@/config/httpService";
import { CaretDownOutlined } from "@ant-design/icons";
import { api } from "@redux/api";
import actions from "@redux/equipmentCategory/actions";


const options = [
    {
        value: 'kpiequipment',
        label: 'Equipment'
    },
    {
        value: 'kpiworkorder',
        label: 'Work Order'
    },
    {
        value: 'kpifiles',
        label: 'Files'
    }
]

const TableNameLookup = (props) => {
    const [value, setValue] = useState(props.formRef?.getFieldValue('table_name'))

    const handleSelect = (e, record) => {

        setValue(e)

        props.formRef && props.formRef.setFieldsValue({
            table_name: e
        })
    }

    return (
        <Select
            style={{ width: '100%' }}
            options={options}
            onSelect={handleSelect}
            value={value}
            dropdownMatchSelectWidth={false}
            onChange={setValue}
        >
        </Select>
    );
}


export default TableNameLookup;