import React from "react"
import { List, Checkbox } from "antd"
import { FileIcon, FolderIcon } from './Icon';
import { justify } from "@antv/g2plot/lib/plots/sankey/sankey";

const listStyle = {
    display: 'flex',
    flexWrap: 'wrap',
    minHeight: 200,

};

export const CardTable = ({ dataSource, selectedRowKeys, setSelectedRowKeys, children, onRow }) => {

    return (
        <List
            style={{
                ...listStyle,
                justifyContent: dataSource.length > 0 ? 'flex-start' : 'center'
            }}
            dataSource={dataSource}
            renderItem={item => (
                <List.Item
                    onDoubleClick={(e) => {
                        onRow(item).onDoubleClick(e)
                    }}
                    style={{
                        width: 94,
                        height: 94,
                        display: 'inline-block',
                        borderRadius: 4,
                        border: '1px solid #E6E6E6',
                        margin: '8px 4px',
                        overflow: 'hidden',
                        position: 'relative'
                    }}>
                        {children({
                            record: item,
                            children:  <div className="flex flex-col items-center text-center">
                            <div className="absolute top-1 left-2">
                                <Checkbox checked={!!selectedRowKeys.find(i => {
                                    return i.key === item.key
                                })} onChange={(e) => {
                                    const isChecked = e.target.checked;
                                    const newSelectedRowKeys = [...selectedRowKeys];
                                    if (isChecked) {
                                        newSelectedRowKeys.push(item)
                                    } else {
                                        const deleteIndex = newSelectedRowKeys.findIndex(i => i.key === item.key)
                                        newSelectedRowKeys.splice(deleteIndex, 1)
                                    }
                                    setSelectedRowKeys(newSelectedRowKeys)
                                }} />
                            </div>
    
                            <div className="mt-4 mb-2">
                                <FileIcon size={34} isFolder={item.isFolder} title={item.title} />
                            </div>
                            <div title={item.name} style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', fontSize: 12, width: 82, userSelect: 'none' }}>{item.name}</div>
                        </div>
                    })}

                </List.Item>
            )}
        />
    )
}