import React, { Component } from 'react';
import {
    Button,
    Checkbox,
    DatePicker,
    Divider,
    Form, Input,
    message,
    PageHeader,
    Popconfirm, Space,
    Spin, Table,
    Tabs,
    Typography,
    Upload
} from 'antd';
import {
    CloseCircleOutlined,
    CopyOutlined,
    DeleteOutlined,
    FileProtectOutlined, MinusCircleOutlined, PlusCircleOutlined,
    SaveOutlined,
    SketchOutlined,
    UnlockOutlined
} from '@ant-design/icons';
import _, { isEqual } from "lodash";
import moment from "moment";
import TagInput from "@components/TagInput/TagInput.js";
import Dragger from "antd/es/upload/Dragger";
import { connect } from "react-redux";
import equipmentCategoryAction from "@redux/equipmentCategory/actions";
import edittableActions from "@redux/EditableTable/actions";
import PermissionSetting from "@components/PermissionSetting/PermissionSetting";

import ComplexDynamicFields from "@components/ComplexDynamicFields";
import { api } from "@redux/api";

import login from '@assets/images/login.jpg';
import notification2 from "@components/Notification2";
import folder from "@assets/icons/folder.png";
import PermissionControl from "@components/Permission/Permission";
import { DetailWrapper } from "@components/Table/DetailWrapper";
import RecordAuditTimeline from "./RecordAuditLog";
import Clone from "@components/Table/Clone";
import Notification2 from "@components/Notification2";
import { FileManagement } from '../FileManagement';
import FileManager from '../FileManager/FileManager';
import GlobalTag from '../global-tag';

const { TabPane } = Tabs;
const { Paragraph } = Typography;


const defaultFileList = [
    {
        uid: '1',
        name: 'default-image.jpg',
        status: 'done',
        url: login,
    },
];

class Detail extends Component {
    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.state = {
            fields: [],
            fileList: [],
            tags: [
                { id: 1, content: 'Record' }
            ],
            visible: false,
            random: 0,
            iframe_key: 0,
            isLoading: false,
            readOnly: true,
            isClone: false,
            unsavedChanges: false,
            currentData: this.props.data,
        };
    }

    componentDidMount() {
        // this.props.SetEquipmentCategory([])
        console.log('handleSubmit', this.props.isClone)
        if (this.props.formRefCallback) {
            this.props.formRefCallback(this.formRef.current);
        }
        let readOnly = true;
        if (this.props.isClone) {
            readOnly = false
        }
        this.setState({
            readOnly: readOnly,
            currentData: null,
            unsavedChanges: false
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.SelectedLatitude !== nextProps.SelectedLatitude
            || this.props.SelectedAddress !== nextProps.SelectedAddress
            || this.props.SelectedLongitude !== nextProps.SelectedLongitude
        ) {
            this.formRef.current.setFieldsValue({
                latitude: nextProps.SelectedLatitude,
                longitude: nextProps.SelectedLongitude,
                address: nextProps.SelectedAddress
            })
        }
        if (!this.formRef.current) {
            return
        }
        let formKeys = Object.keys(this.formRef.current.getFieldsValue());

        if (formKeys.includes('key_spec_1_name') &&
            _.get(this.props.SelectedEquipmentCategory, 'value') !== _.get(nextProps.SelectedEquipmentCategory, 'value')
            && nextProps.SelectedEquipmentCategory !== ''
        ) {
            this.formRef.current.setFieldsValue({
                category: _.get(nextProps.SelectedEquipmentCategory, 'record.name'),
                key_spec_1_name: _.get(nextProps.SelectedEquipmentCategory, 'record.spec1'),
                key_spec_2_name: _.get(nextProps.SelectedEquipmentCategory, 'record.spec2'),
                key_spec_3_name: _.get(nextProps.SelectedEquipmentCategory, 'record.spec3'),
                key_spec_4_name: _.get(nextProps.SelectedEquipmentCategory, 'record.spec4'),
                key_spec_5_name: _.get(nextProps.SelectedEquipmentCategory, 'record.spec5'),
                key_spec_6_name: _.get(nextProps.SelectedEquipmentCategory, 'record.spec6'),
                key_spec_7_name: _.get(nextProps.SelectedEquipmentCategory, 'record.spec7'),
                key_spec_8_name: _.get(nextProps.SelectedEquipmentCategory, 'record.spec8')
            })
        }

        // console.log(_.get(this.props.SelectedCompany, 'value'), _.get(nextProps.SelectedCompany, 'value'))
        if (formKeys.includes('company')
            && _.get(this.props.SelectedCompany, 'value') !== _.get(nextProps.SelectedCompany, 'value')) {
            this.formRef.current.setFieldsValue({
                company: _.get(nextProps.SelectedCompany, 'id'),
                user: []
            })
        }

        if (formKeys.includes('equipment')
            && _.get(this.props.SelectedEquipment, 'value') !== _.get(nextProps.SelectedEquipment, 'value')) {
            this.formRef.current.setFieldsValue({
                equipment: _.get(nextProps.SelectedEquipment, 'value')
            })
        }

        if (!isEqual(nextProps.data, this.props.data)) {
            this.setState({
                currentData: nextProps.data,
            });
        }

    }

    // handleEdit = (e) => {
    //     this.setState({
    //         disabled: false
    //     })
    // }


    handleClose = (e) => {

        if (typeof this.props.onClose !== "undefined") {
            this.props.onClose()
        }

        // if (!this.props.isLookupCreateNew) {
        //     this.props.OpenDrawer(false);
        // }
    }

    // handleSave = (e) => {
    //     console.log(e)
    // }

    handleSubmit = async (data) => {
        console.log('handleSubmit', this.props.isClone)
        await this.formRef.current.validateFields();
        this.setState({
            isLoading: true
        })
        for (let i = 0; i < Object.keys(data).length; i++) {
            // if (data[Object.keys(data)[i]] === null || data[Object.keys(data)[i]] === '') {
            //     delete data[Object.keys(data)[i]]
            // }
            if (Object.keys(data)[i] === 'tag' || Object.keys(data)[i] === 'externalUrl') {
                data[Object.keys(data)[i]] = JSON.stringify(data[Object.keys(data)[i]])
            }
        }

        if (!this.props.isLookupCreateNew && !this.props.isClone) {
            let result = await this.props.handOnlineSave(data.id, data)
            // console.log('handleSubmit result', result);

            this.setState({
                disabled: true,
                isLoading: false,
                readOnly: true
            })

            if (result && result?.status >= 200 && result?.status < 300) {
                notification2['success']({
                    message: 'Success',
                    description: 'Record was successfully saved', placement: 'topLeft'
                });

                // this.props.OpenDrawer(false);
            }
        } else {
            let result = await this.props.createRecords(data)
            this.setState({
                disabled: true,
                isLoading: false,
                readOnly: true
            })
            if (result && result?.status >= 200 && result?.status < 300) {
                notification2['success']({
                    message: 'Success',
                    description: 'Record was successfully saved', placement: 'topLeft'
                });
                this.handleClose()
            }
        }

    }

    handleTag = (e) => {
        this.formRef.current.setFieldsValue({
            tag: e
        })
    }

    // handleUpload(info) {
    //     this.formRef.current.setFieldsValue({
    //         image:[...info.fileList]
    //     })
    // }
    iframeRefresh() {
        this.setState({ iframe_key: this.state.iframe_key + 1 });
    }

    handleDelete = (e) => {
        // console.log('details data', this.props.data)
        let id = _.get(this.props.data, 'id')
        this.props.handleDelete(id)
    }

    handleClone = (e) => {

        if (this.props.isResetPassword) {
            let data = this.formRef.current.getFieldsValue();
            this.props.handleRegister(e, data)
        } else {
            this.setState({
                isCloneOpen: true,
            })
        }

    }

    parseJson = (data) => {
        try {
            return JSON.parse(data);
        } catch (e) {
            return []
        }
    }

    onTabChange = (key) => {

        this.setState({
            selectedTab: key,
            random: this.state.random + 1
        })
    }

    sanitizeUrl = (url) => {
        return url.replaceAll('&amp;', '&')
    }

    showPopconfirm = (e) => {
        this.setState({
            visible: true
        })
    }
    handleConfirmCancel = (e) => {
        this.setState({
            visible: false
        })

    }

    handleResetPassword = (e) => {
        // console.log('handleResetPassword', this.props.data)
        let email = _.get(this.props.data, 'email')
        if (email === '') {
            return notification2['error']({ message: 'Error', description: 'Please enter email', placement: 'topLeft' })
        }
        this.setState({
            isLoading: true
        })
        api.forgetpassword(email).then(res => {
            this.setState({
                isLoading: false
            })
            return notification2['success']({
                message: 'Success',
                description: 'Password reset successfully, please check your email', placement: 'topLeft'
            });
        })

    }

    normalizeFormData = (formData) => {
        let normalizedData = {};
        this.props.formFields.forEach(field => {
            if (field.visible && formData?.hasOwnProperty(field.name)) {
                if (field.type === 'Date') {
                    normalizedData[field.name] = moment(formData[field.name]).format('YYYY-MM-DD')
                } else {
                    let value = formData[field.name];
                    normalizedData[field.name] = (value === null || value === '') ? '' : value;
                }

            }
        });
        return normalizedData;
    };

    onValuesChange = (changedValues, allValues) => {
        const normalizedAllValues = this.normalizeFormData(allValues);
        const normalizedPropsData = this.normalizeFormData(this.props.data);
        const hasChanges = !isEqual(normalizedAllValues, normalizedPropsData);
        this.setState({ unsavedChanges: hasChanges });
    };
    openEditMode = (e) => {
        // console.log('unsaved changes0', this.state.unsavedChanges)
        // if (this.state.unsavedChanges) {
        //     console.log('unsaved changes')
        //     Notification2['warning']({
        //         message: 'You have unsaved changes',
        //     })
        // } else {
        //     this.setState({
        //         readOnly: !e
        //     })
        // }
        this.setState({
            readOnly: !e
        })
    }

    renderCloseEdit = () => {
        const { unsavedChanges } = this.state
        if (unsavedChanges) {
            return (
                <Popconfirm
                    title="You have unsaved changes.Do you want to save them before leaving?"
                    // visible={this.state.visible}
                    onConfirm={() => this.formRef.current.submit()}
                    onCancel={() => this.openEditMode(false)}
                    okText={'Save'}
                    cancelText={'Close Edit'}
                >
                    <Button type='primary' key="closeEditMode" form={this.props.formKey + 'form'}
                        // onClick={() => this.openEditMode(false)}
                        icon={<i className="fa-regular fa-circle-xmark pr-2"></i>}
                    >Close Edit</Button>
                </Popconfirm>
            )
        } else {
            return (
                <Button type='warning' key="closeEditMode" form={this.props.formKey + 'form'}
                    onClick={() => this.openEditMode(false)}
                    icon={<i className="fa-regular fa-circle-xmark pr-2"></i>}
                >Close Edit</Button>
            )
        }

    }

    renderOperationButton = (e) => {
        return [
            // <Button type='primary' key="1" onClick={this.handleEdit}>Edit</Button> ,
            <div className={'mr-10'}>{this.props.isResetPassword ?
                <Button type='primary' key="1" onClick={this.handleResetPassword}
                    icon={<UnlockOutlined />}> ResetPassword</Button> : ''}</div>,


            <>{this.state.readOnly && this.props.data && <PermissionControl allowedPermissions={'3:' + this.props.columnProps?.name}>
                <Popconfirm
                    title="Do you want to delete this record"
                    visible={this.state.visible}
                    onConfirm={this.handleDelete}
                    onCancel={() => this.handleConfirmCancel()}
                >
                    {this.state.readOnly && <Button key="2" danger onClick={() => this.showPopconfirm()}
                        icon={<DeleteOutlined />}
                        disabled={this.props.isNewRecords}>Delete</Button>}
                </Popconfirm>
            </PermissionControl>}</>
            ,


            <div className={'flex mx-5 gap-3'}>
                <PermissionControl allowedPermissions={'2:' + this.props.columnProps?.name}>
                    {this.state.readOnly && !this.props.readonly && <Button type='primary' warning key="3" form={this.props.formKey + 'form'}
                        onClick={this.handleClone} icon={<CopyOutlined />}
                        disabled={this.props.isNewRecords}>Duplicate</Button>}
                </PermissionControl>

                <PermissionControl allowedPermissions={'2:' + this.props.columnProps?.name}>
                    {(!this.state.readOnly || !this.props.data) &&
                        <Button type='primary' key="submit" form={this.props.formKey + 'form'} htmlType="submit"
                            icon={<SaveOutlined />}
                        >Save</Button>}
                    {this.state.readOnly && this.props.data && !this.props.readonly ?
                        <Button type='primary' key="openEditMode" form={this.props.formKey + 'form'}
                            onClick={() => this.openEditMode(true)}
                            icon={<i className="fa-regular fa-pen-to-square pr-2"></i>}
                        >Edit</Button> :
                        (this.props.data && !this.props.readonly && this.renderCloseEdit())}

                </PermissionControl>
            </div>,

            <>{this.state.readOnly && <Button ghost style={{ backgroundColor: 'rgb(133 148 165)' }} key="1"
                onClick={this.handleClose}
                icon={<CloseCircleOutlined />}>Close</Button>}</>,
        ]
    }

    renderCustomJSON = (data) => {
        if (data?.length === 0) {
            return null
        }
        const columns = []
        const firstRowKeys = Object.keys(data[0])
        firstRowKeys.forEach((column) => {
            columns.push({
                title: column,
                dataIndex: column,
                key: column,
                className: column === 'id' ? 'hidden' : ''
            })
        })

        return (
            <Table columns={columns} className={'w-full'} dataSource={data} pagination={false} rowKey="id" />
        )
    }



    render() {
        let data = this.props.data;

        let fields = [];
        if (data && this.props.formFields && !this.props.isNewRecords) {

            this.props.formFields.map(e => {
                let field = e
                if (e.type === 'Date') {
                    field['value'] = moment(data[e.name]).format('YYYY-MM-DD');
                } else if (e.type === 'Lookup') {
                    field['value'] = _.get(data[e.name + '_name'], 'name');
                } else if (e.type === 'Lookup_text') {
                    field['value'] = data[e.name];
                } else if (e.type === 'Tag' || e.type === 'JSON') {
                    field['value'] = this.parseJson(data[e.name]);
                } else {
                    field['value'] = data[e.name];
                }

                fields.push(field)
                return fields
            }
            )
        } else {
            this.props.formFields && this.props.formFields.map(e => {
                let field = e
                delete field['value']

                fields.push(field)
                return fields
            }
            )
        }


        // console.log('fields', fields)
        const props = {
            defaultFileList: defaultFileList,
            accept: "image/png, image/jpeg, image/jpg",
            multiple: false,
            maxCount: 1,
            listType: "picture",
            beforeUpload: file => {
                // console.log('file', file)
                if (file.type !== 'image/png' && file.type !== 'image/jpg' && file.type !== 'image/jpeg') {
                    message.error(`${file.name} is not a PNG,JPG,JPEG file`);
                }
                // console.log('file', file.type, file.type !== 'image/png' && file.type !== 'image/JPG' && file.type !== 'image/JPEG')
                return (file.type !== 'image/png' && file.type !== 'image/jpg' && file.type !== 'image/jpeg') ? Upload.LIST_IGNORE : true;
            },
            // onChange: (info) => this.handleUpload(info),
        };

        const externalUrls = this.parseJson(_.get(data, 'externalUrl', '[]'));

        for (let i = 0; i < externalUrls.length; i++) {
            if (externalUrls[i].label === 'externalUrl') {
                externalUrls[i].value = 'https://www.figma.com/embed?embed_host=fta&url=' + externalUrls[i].value
            }
        }

        const table_name = this.props.columnProps?.name
        const record_id = this.props.data?.id ?? null
        const equipmentId = (this.props.columnProps?.name === 'equipment' ? this.props.data?.id : this.props.data?.equipment) ?? null

        // console.log('detail', data, perm, perm[this.props.columnProps.name].edit)
        return (
            <DetailWrapper>
                <Spin spinning={this.state.isLoading}>
                    <Tabs defaultActiveKey="1" destroyInactiveTabPane={true} onChange={this.onTabChange}
                        tabBarExtraContent={{
                            right: <div
                                className={'text-xl font-bold'}>{data?.name ? ('Record Name:  ' + data?.name) : _.get(this.props.columnProps, 'title', [])}</div>
                        }}
                    >
                        <TabPane tab={<span> <i className="fa-regular fa-rectangle-list pr-5"></i>Detail </span>}
                            key="1">
                            <div>

                                <div className={'slds-m-around_x-small'}>
                                    <PageHeader
                                        title=''
                                        className="site-page-header justify-center"
                                        subTitle={''}
                                        extra={this.renderOperationButton()}

                                    />
                                </div>

                                <div className={'slds-m-around_x-small'}>
                                    <div className={'flex p-8 '}>
                                        <div className={'w-full'}>
                                            <Form
                                                onFinish={this.handleSubmit}
                                                onValuesChange={this.onValuesChange}
                                                layout="horizontal"
                                                labelCol={{ span: 8 }}
                                                fields={fields}
                                                ref={this.formRef}
                                                className={this.props.formClassName ? this.props.formClassName : 'detailForm'}
                                                id={this.props.formKey + 'form'}
                                            >

                                                {fields.map(e => {

                                                    return (

                                                        this.state.readOnly && this.props.data ?
                                                            <>
                                                                {e.title === 'Separator' ?
                                                                    <Divider /> :
                                                                    e.type === 'File' ?
                                                                        <Form.Item label={e.title} name={e.name}
                                                                            className={e.length}
                                                                            hidden={!e.visible}
                                                                            getValueFromEvent={({ file }) => file.originFileObj}>
                                                                            <img src={e.value} width={'80px'} />
                                                                        </Form.Item> :
                                                                        e.type === 'checkbox' ?
                                                                            <Form.Item label={e.title} name={e.name}
                                                                                className={e.length}
                                                                                hidden={!e.visible}>
                                                                                {e.render(this.state.readOnly)}
                                                                            </Form.Item> :
                                                                            e.type === 'radio' ?
                                                                                <Form.Item label={e.title}
                                                                                    name={e.name}
                                                                                    className={e.length}
                                                                                    hidden={!e.visible}>
                                                                                    {e.render(this.state.readOnly)}
                                                                                </Form.Item> :
                                                                                e.type === 'custom_text' ?
                                                                                    <Form.Item label={e.title}
                                                                                        name={e.name}
                                                                                        className={e.length}
                                                                                        hidden={!e.visible}>
                                                                                        {e.render(fields, this.state.readOnly)}
                                                                                    </Form.Item> :
                                                                                    e.type === 'custom_type' ?
                                                                                        <Form.Item label={e.title}
                                                                                            name={e.name}
                                                                                            className={e.length}
                                                                                            hidden={!e.visible}>
                                                                                            {e.render(e.value)}
                                                                                        </Form.Item> :
                                                                                        e.type === 'custom_type' ?
                                                                                            <Form.Item label={e.title}
                                                                                                name={e.name}
                                                                                                className={e.length}
                                                                                                hidden={!e.visible}>
                                                                                                {e.render(e.value)}
                                                                                            </Form.Item> :
                                                                                            e.type === 'Lookup_text' ?
                                                                                                <Form.Item label={e.title}
                                                                                                    name={e.name}
                                                                                                    className={e.length}
                                                                                                    hidden={!e.visible}>
                                                                                                    <span>{e.value}</span>
                                                                                                </Form.Item> :
                                                                                                e.type === 'customJSON' ?
                                                                                                    (this.renderCustomJSON(e.value)) :
                                                                                                    // e.render(e.value):
                                                                                                    (
                                                                                                        <Form.Item label={e.title}
                                                                                                            name={e.name}
                                                                                                            className={e.length}
                                                                                                            hidden={!e.visible}>
                                                                                                            <span>{e.value}</span>
                                                                                                        </Form.Item>
                                                                                                    )
                                                                }
                                                            </> :
                                                            <>
                                                                {e.title === 'Separator' ? <Divider /> :
                                                                    (e.type === 'File' ?
                                                                        (
                                                                            <div>
                                                                                {/*<img alt="image" src={e.value} style={{width: '5rem'}}*/}
                                                                                {/*/>*/}
                                                                                <Form.Item label={e.title}
                                                                                    name={e.name}
                                                                                    className={e.length}
                                                                                    getValueFromEvent={({ file }) => file.originFileObj}>

                                                                                    <Dragger {...props}
                                                                                        className={e.length}>
                                                                                        <p className="ant-upload-drag-icon flex justify-center">
                                                                                            <img src={folder}
                                                                                                alt="folder"
                                                                                                width={'40px'} />
                                                                                        </p>
                                                                                        <p className="ant-upload-text">Click
                                                                                            or drag
                                                                                            file to
                                                                                            this
                                                                                            area to
                                                                                            upload</p>
                                                                                        <p className="ant-upload-hint">
                                                                                            Support for a
                                                                                            PNG,JPG,JPEG
                                                                                            file up to
                                                                                            5MB.
                                                                                        </p>
                                                                                    </Dragger>
                                                                                </Form.Item>
                                                                            </div>
                                                                        )
                                                                        :
                                                                        (e.type === 'JSON' ?
                                                                            <ComplexDynamicFields name={e.name}
                                                                                value={e.value} /> :

                                                                            (e.type === 'date' ?
                                                                                <Form.Item label={e.title}
                                                                                    name={e.name}
                                                                                    className={e.length}
                                                                                    valuePropName={moment(e.value)}

                                                                                    rules={[
                                                                                        {
                                                                                            required: e.required,
                                                                                            message: 'this field is required',
                                                                                        },
                                                                                    ]}
                                                                                    hidden={!e.visible}>
                                                                                    <DatePicker
                                                                                        defaultValue={e.value ? e.value : null} />
                                                                                </Form.Item> :
                                                                                e.type === 'checkbox' ?
                                                                                    <Form.Item label={e.title} name={e.name}
                                                                                        className={e.length}
                                                                                        hidden={!e.visible}>
                                                                                        {e.render(this.state.readOnly)}
                                                                                    </Form.Item> :
                                                                                    e.type === 'radio' ?
                                                                                        <Form.Item label={e.title}
                                                                                            name={e.name}
                                                                                            className={e.length}
                                                                                            hidden={!e.visible}>
                                                                                            {e.render(this.state.readOnly)}
                                                                                        </Form.Item> :

                                                                                        (e.type === 'custom_type' ?
                                                                                            <Form.Item label={e.title}
                                                                                                name={e.name}
                                                                                                className={e.length}
                                                                                                hidden={!e.visible}>
                                                                                                {e.render(e.value)}
                                                                                            </Form.Item> :
                                                                                            <Form.Item label={e.title}
                                                                                                name={e.name}
                                                                                                dependencies={e.dependencies}
                                                                                                shouldUpdate={true}
                                                                                                className={e.length}
                                                                                                tooltip={e.tooltip}
                                                                                                rules={[
                                                                                                    {
                                                                                                        required: e.required,
                                                                                                        message: 'this field is required',
                                                                                                    },
                                                                                                ]}
                                                                                                hidden={!e.visible}
                                                                                                initialValue={e?.initialValue}>

                                                                                                {(e.title === 'Tag' ?
                                                                                                    <TagInput
                                                                                                        handleTag={this.handleTag}
                                                                                                        value={e.value} /> :
                                                                                                    ((e.type === 'Lookup_text' || e.type === 'Lookup' || e.type === 'customJSON') ? e.render(e.value) :
                                                                                                        (e.dependent ? e.render(fields) : e.render))
                                                                                                )}


                                                                                            </Form.Item>
                                                                                        )
                                                                            )
                                                                        )
                                                                    )}
                                                                {e.datalist}
                                                            </>
                                                    )
                                                }
                                                )}


                                            </Form>
                                        </div>

                                        <div className={'pl-10'}>
                                            {this.props.isChat ? <div style={{ minWidth: '35rem' }}>
                                                <div className={'small-title'}>{'Comment'}</div>
                                                {this.props.postComment(_.get(this.props.data, 'id', null))}
                                            </div>
                                                : ''}


                                        </div>

                                    </div>


                                </div>

                                <Tabs defaultActiveKey="1">

                                    {this.props.userPermission ?
                                        <TabPane
                                            tab={<span> <i className="fa-solid fa-user-lock p-5"></i>Permission </span>}
                                            key="1">
                                            {_.get(data, 'id') ? <PermissionSetting
                                                readOnly={this.state.readOnly}
                                                role={_.get(data, 'role')}
                                                handOnlineSave={this.handOnlineSave}
                                                handleDelete={this.deleteRecord}
                                                selecteduser={_.get(data, 'id')}
                                            /> : ''}
                                        </TabPane> : ''}
                                    {!this.props.userPermission && this.props.isRelated ?
                                        <TabPane tab={<span> <i className="fa-solid fa-table-list"></i>Related </span>}
                                            key="1">
                                            {this.props.formRelated(_.get(this.props.data, 'id', null))}
                                        </TabPane> : ''}

                                </Tabs>

                            </div>
                        </TabPane>
                        {
                            externalUrls.map((e, key) => {

                                const url = e.value;
                                return <TabPane tab={<span> <SketchOutlined /> {e?.label} </span>} key={key + 2}>
                                    <div className={'flex'}>
                                        <Button style={{ width: '100px' }} type={'primary'} onClick={() => {
                                            this.iframeRefresh();
                                        }}>Reload</Button>
                                        <Button style={{ width: '100px' }} type={''} onClick={() => {
                                            window.open(url, "_blank");
                                        }}>Open in new tab</Button>
                                    </div>
                                    {this.state.selectedTab === (key + 2).toString() ? <iframe
                                        key={this.state.iframe_key}
                                        style={{ overflow: 'scroll' }}
                                        height="1600"
                                        width="100%"
                                        src={this.sanitizeUrl(url)}
                                        allowFullScreen
                                    /> : ''}
                                </TabPane>
                            })
                        }

                        {/*{this.props.isChat ?*/}
                        {/*    <TabPane tab={<span> <CommentOutlined/>Comments </span>} key="2">*/}
                        {/*        /!*<PostComment data={this.props.data} handleSubmit={this.handleSaveComment}/>*!/*/}
                        {/*        {this.props.postComment(_.get(this.props.data, 'id', null))}*/}
                        {/*    </TabPane> : ''}*/}
                        {this.props.isAttachments ?
                            <TabPane tab={<span> <i className="fa-solid fa-file-pdf pr-5"></i>Attachments </span>}
                                key="3">
                                {_.get(this.props.data, 'id', null) ? <>
                                    <FileManagement
                                        tableName={table_name}
                                        recordId={record_id}
                                        equipmentId={equipmentId}
                                    />
                                    {/* <FileManager
                                        tableName={_.get(this.props.columnProps, 'name', [])}
                                        recordId={_.get(this.state.data, 'id', null)}
                                    /> */}
                                </> : ''}
                            </TabPane> : ''}


                        <TabPane tab={<span><i className="fa-solid fa-timeline pr-5"></i>Log </span>} key="5">
                            <PermissionControl allowedPermissions={'3:' + 'log'}>
                                <div style={{ minWidth: '35rem' }}>
                                    <RecordAuditTimeline moduleId={this.props.data?.id}
                                        module={this.props.columnProps?.title} />
                                </div>
                            </PermissionControl>
                        </TabPane>

                        {
                            this.props.isAdditionalForm ?

                                <TabPane tab={<span> <FileProtectOutlined />Functional Testing Form </span>} key="4">
                                    <PermissionControl allowedPermissions={'1:functiontesting'}>
                                        {this.props.renderAdditionalForm(this.props.data)}
                                    </PermissionControl>
                                </TabPane>

                                : ''
                        }
                    </Tabs>


                </Spin>

                {this.state.isCloneOpen ? <Clone

                    formClassName={this.props.formClassName}
                    isCloneOpen={this.state.isCloneOpen}
                    toggleCloneDrawer={(e) => this.setState({ isCloneOpen: e })}

                    formFields={this.props.formFields}
                    createRecords={this.props.createRecords}
                    columnProps={this.props.columnProps}
                    userPermission={this.props.userPermission}
                    data={this.props.data}
                /> : ''}
                {/* <div className='absolute bottom-1' style={{ width: 'calc(100% - 40px)' }}>
                    <GlobalTag tableName={table_name} recordId={record_id} facility={this.props.facility} />
                </div> */}
            </DetailWrapper>
        );
    }
}

const mapStateToProps = state => {
    return {
        is_companyadmin: _.get(state.Auth, 'is_companyadmin'),
        SelectedLatitude: _.get(state.Facility, 'geolocation.latitude'),
        SelectedLongitude: _.get(state.Facility, 'geolocation.longitude'),
        SelectedAddress: _.get(state.Facility, 'address'),
        SelectedCompany: _.get(state.Company, 'selectedCompany'),
        SelectedEquipment: _.get(state.Equipment, 'selectedEquipment'),
        SelectedEquipmentCategory: _.get(state.EquipmentCategory, 'selectedEquipmentCategory'),
        Role: _.get(state, 'Auth.role') ? Object.keys(_.get(state, 'Auth.role'))[0] : null,
        facility: _.get(state, 'Facility.selected.Id'),
    }
}

const mapDispatchToProps = dispatch => {
    return {
        SetEquipmentCategory: (e) => dispatch(equipmentCategoryAction.SelectedEquipmentCategory(e)),
        OpenDrawer: (e) => dispatch(edittableActions.OpenDrawer(e)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Detail);
