import React, { useMemo, useRef } from "react";
import { Radio, Modal } from "antd";
import { useRole } from "@/hooks/use-role";
import { useSelector } from "react-redux";
import { convertToTreeStructure, getCompanyId } from "./convertToTreeStructure";
import { getFormValuesByFormRef } from "./getFormValuesByFormRef";

const AccountManagerAllowKeys = [2.2, 3, 4];
const CompanyAdminAllowKeys = [4];

export const RolePicker = (props) => {
    const { formRef, readOnly, onChange: propsOnChange } = props;
    const allFacilities = useSelector(state => state.Facility.data);
    const companyTreeOptions = useMemo(() => convertToTreeStructure(allFacilities), [allFacilities]);
    const RoleKey = useRole();
    const isComfirmModalOpen = useRef(false);
    const { company } = getFormValuesByFormRef(formRef);
    const [modal, contextHolder] = Modal.useModal();
    const options = [
        { value: 1, label: 'Super User' },
        { value: 2.1, label: 'Account Manager' },
        { value: 2.2, label: 'Portfolio Manager' },
        { value: 3, label: 'Company Admin' },
        { value: 4, label: 'Standard User' },
    ];

    /**
     * 1.0 - Super User
     * Super User可以assign所有的角色
     * 2.1 - Account Manager
     * Account Manager可以assign给Account Manager, Company Admin, Standard User
     * 2.2 - Portfolio Manager
     * Portfolio Manager 没有任何用户assign权限
     * 3.0 - Company Admin
     * Company Admin可以assign Standard User
     * 4.0 - Standard User
     * Standard User没有任何用户assign权限
     */
    const permissionFilterOptions = useMemo(() => {
        if (RoleKey === 1) {
            return options
        } else if (RoleKey === 2.1) {
            return options.filter(option => AccountManagerAllowKeys.includes(option.value));
        } else if (RoleKey === 3) {
            return options.filter(option => CompanyAdminAllowKeys.includes(option.value));
        }
        return []
    }, [RoleKey]);


    const setRole = (role) => {
        if (formRef) {
            let facilities = [];
            let companies = [];
            if(role === 1) {
                facilities = allFacilities.map(facility => facility.record.id);
                companies = companyTreeOptions.map(company => getCompanyId(company.value));
            }else if(role === 3) {
                facilities = allFacilities.filter(facility => facility.record.company_id === company).map(facility => facility.record.id);
                companies = [company];
            }

            if (formRef.setFieldsValue) {
                formRef.setFieldsValue({ role, user: facilities, companies });
            } else {
                formRef.current.setFieldsValue({ role, user: facilities, companies });

            }
        }
    }

    const onChange = (e) => {

        if (propsOnChange) {
            propsOnChange(e)
        }
        const targetValue = e.target.value;
        setRole(targetValue);
    }

    return <>
    <Radio.Group
        value={props?.value}
        onChange={onChange}
        disabled={readOnly}
    >
        {permissionFilterOptions.map(option => (
            <div style={{cursor: readOnly ? '' : 'pointer', display: 'inline-block'}} onClick={() => {
                if(readOnly) {
                    return
                }
                if(option.value === props?.value) {
                    return
                }
                if(isComfirmModalOpen.current) {
                    return
                }
                isComfirmModalOpen.current = true;
                modal.confirm({
                    title: 'Warning!',
                    content: <p>Are you sure you want to change role?</p>,
                    onOk: () => {
                        const element = document.getElementById(`${option.value}-role-picker`);
                        element.style.pointerEvents = 'auto';
                        element.click();
                        element.style.pointerEvents = 'none';
                        isComfirmModalOpen.current = false;
                    },
                    onCancel: () => {
                        isComfirmModalOpen.current = false;
                    }
                })

            }}>
                <Radio id={`${option.value}-role-picker`} style={{pointerEvents:'none'}} key={option.value} value={option.value}>{option.label}</Radio>
            </div>
            ))}
        </Radio.Group>
        {contextHolder}
    </>
}