import React from "react";
import {store} from '@redux/store';
import actions from '@redux/EditableTable/actions';
import TextArea from "antd/es/input/TextArea";

const renderDrawer = (text, id) => {
    store.dispatch(actions.OpenDrawer(true, id))
}
export const TagManageProps = {
    title: 'TagManage',
    name: 'TagManage',
    description: ''
}


export const TagManageColumns = [
    {
        title: 'id',
        dataIndex: 'id',
        key: 'id',
        editable: false,
        search: false,
        importIngore: true,
        width: '4%'
    },
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        search: false,
        edit: true,
        sorter: (a, b) => a.name?.localeCompare(b.name),
        formItemProps: {
            rules: [
                {
                    required: true,
                    message: 'required',
                },
            ],
        },
        render: (text, record, _, action) => (
            <a onClick={() => action ? renderDrawer(text, record.id) : ''}>{text}</a>
        ),
        renderFormItem: () => <TextArea rows={1}/>,
    },
    {
        title: 'type',
        dataIndex: 'type',
    },
    {
        title: 'created by system',
        dataIndex: 'is_sys_created',
        render(record) {
            return record ? 'yes' : 'no'
        }
    },
    {
        title: 'createBy',
        dataIndex: 'createdby',
        key: 'createBy',
        search: false,
        edit: false,
    },
    {
        title: 'createDate',
        dataIndex: 'created_date',
        key: 'createDate',
        search: false,
        edit: false,    
        valueType: 'date',
    },
    // {
    //     title: 'Type',
    //     dataIndex: 'type',
    //     key: 'type',
    //     edit: true,
    //     hideInTable: window.innerWidth < 760,
    //     sorter: (a, b) => a.type?.localeCompare(b.type),
    // },
];
