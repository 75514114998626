import React from 'react'
import { Form, Modal, TreeSelect } from 'antd'

export const MoveToModal = ({treeData, open, setOpen, moveForm, handleMoveTo}) => {


  if(!open) {
    return null
  }
  const formatTreeData = (treeData) => treeData.map(item => {
    if(Array.isArray(item.children) && item.children.length > 0) {
      return {
        value:  item.isRoot ? 'root' : item.id,
        title: item.title,
        label: item.title,
        children: formatTreeData(item.children),
        disabled: item.isSystem ? !item?.context?.canMove : false
      }
    }
    return {
      value:  item.isRoot ? 'root' : item.id,
      label: item.title,
      title: item.title,
      disabled: item.isSystem ? !item?.context?.canMove : false
    }
  })

  return (<Modal title="Move to" open={open} onCancel={() => setOpen(false)} onOk={handleMoveTo}>
    <Form form={moveForm}>
      <Form.Item label="Folder" name="folder" rules={[{required: true}]}>
        <TreeSelect
          treeData={formatTreeData(treeData)}
          // treeCheckable
          showCheckedStrategy={TreeSelect.SHOW_PARENT}
          // treeCheckStrictly
          treeDefaultExpandAll
          // treeDataSimpleMode
          // placeholder="Please select"
          // onChange={(value, label, extra) => {
          //   console.log(value, label, extra)
          // }}
        />
      </Form.Item>
    </Form>
  </Modal>)
}