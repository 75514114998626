import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Badge, Tooltip } from 'antd';
import { store } from "@redux/store";
import { api } from "@redux/api";
import { useSelector } from "react-redux";
import { WorkerCommentTooltip } from "@containers/facility/Workorder/WorkorderTooltip";
import { ENV } from '@config/env';

const { wsURL } = ENV;

const WorkorderNotification = React.memo(({ record }) => {
    const facility = useMemo(() => {
        const state = store.getState().Facility.selected;
        return {
            name: state.value.replaceAll(' ', '').replaceAll('-', ''),
            id: state.Id
        };
    }, []);

    const [notifications, setNotifications] = useState({
        unread: record.unread_notifications_count,
        read: record.read_notifications_count
    });

    const user_id = useSelector(state => state.Auth.userId);
    const drawer = useSelector(state => state.EditableTable.drawer);
    const drawer_id = useSelector(state => state.EditableTable.id);

    const setReadComment = useCallback(() => {
        api.updateWorkorderComment(record.id, { status: 'read' })
            .then(() => {
                setNotifications(prev => ({
                    unread: 0,
                    read: prev.read + prev.unread
                }));
            })
            .catch(error => console.error('Error updating notification status:', error));
    }, [record.id]);

    useEffect(() => {
        if (record.id === drawer_id && drawer === true) {
            setReadComment();
        }
    }, [drawer, drawer_id, record.id, setReadComment]);

    useEffect(() => {
        let isActive = true;
        const ws = new WebSocket(`${wsURL}kpiworkordercomment/?user_id=${user_id}&parent_id=${record.id}`);

        ws.onopen = () => console.log('Connected to websocket server');

        ws.onmessage = e => {
            if (!isActive) return;
            const data = JSON.parse(e.data);
            if (data.parent_id === record.id) {
                setNotifications(prev => {
                    if (data.type === 'deleted') {
                        return { ...prev, read: Math.max(0, prev.read - 1) };
                    } else {
                        return { ...prev, unread: prev.unread + 1 };
                    }
                });
            }
        };

        return () => {
            isActive = false;
            ws.close();
        };
    }, [record.id, user_id]);

    const handleTooltipOpenChange = useCallback((open) => {
        if (open) setReadComment();
    }, [setReadComment]);

    const totalNotifications = notifications.unread + notifications.read;

    return (
        <Tooltip
            placement='topLeft'
            trigger={['click']}
            overlayStyle={{ maxWidth: '60rem' }}
            onOpenChange={handleTooltipOpenChange}
            title={WorkerCommentTooltip(record.id)}
            color='lightgrey'
        >
            {totalNotifications > 0 ? (
                <Badge count={notifications.unread} size="small">
                    <i className="fas fa-comment-dots text-primary text-xl" style={{ color: '#faad14', fontSize: '18px' }}></i>
                </Badge>
            ) : (
                <Badge size="small">
                    <i className="fa-regular fa-comment-dots text-primary text-xl" style={{ color: 'grey', fontSize: '18px' }}></i>
                </Badge>
            )}
        </Tooltip>
    );
});

export default WorkorderNotification;
