import React from "react";
import styled from "styled-components";
import {WorkorderComment} from "@containers/facility/Workorder/WorkorderComment";

const WorkerCommentTooltipWrapper = styled.div`

  .ant-comment-content-author-time{
    color: #436494;
  }

`

export function WorkerCommentTooltip(workorderId) {

    return (

        <WorkerCommentTooltipWrapper>
            <div className="p-4 shadow rounded-lg text-sm " >
                <div className="mb-4">

                    {workorderId&&<WorkorderComment id={workorderId} boxHeight={'50vh'}/>}

                </div>

            </div>
        </WorkerCommentTooltipWrapper>

    );
}

