import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Select } from 'antd';

const FacilityLookup = (props) => {
    const facility = useSelector(state => state.Facility.data);
    const [value, setValue] = useState(props.formRef?.getFieldValue('facility'))
    const handleSelect = (e, record) => {

        setValue(e)

        props.formRef && props.formRef.setFieldsValue({
            facility: e
        })
    }

    return (
        <Select
            style={{ width: '100%' }}
            options={facility.map(item => ({
                value: item.Id,
                label: item.value
            }))}
            onSelect={handleSelect}
            value={value}
            dropdownMatchSelectWidth={false}
            onChange={setValue}
        >
        </Select>
    );
}


export default FacilityLookup;