import Input from "antd/es/input";
import React from "react";
import { Radio, Select } from "antd";

import NewCompanyFacilityPicker from "@containers/Lookup/CompanyFacilityPicker/NewCompanyFacilityPicker";
import { RolePicker } from "@containers/Lookup/CompanyFacilityPicker/RolePicker";
import CompanyLookup from "@containers/Lookup/CompanyLookup";

export const CustomUserFormFields = (formRef, handleRoleChange, UserId, RoleKey, Roles) => ([
    {
        title: 'key',
        name: 'id',
        order: 0,
        visible: false,
        length: 0,
        type: 'text',
    },
    {
        title: 'UserName',
        name: 'username',
        order: 1,
        visible: true,
        length: "md:w-1/2 lg:w-1/2 sm:w-full",
        type: 'text',
        required: true,
        render: <Input />
    },
    {
        title: 'Email',
        name: 'email',
        order: 1,
        visible: true,
        length: "md:w-1/2 lg:w-1/2 sm:w-full",
        type: 'text',
        required: true,
        render: <Input />
    },


    {
        title: 'FirstName',
        name: 'first_name',
        order: 5,
        visible: true,
        required: true,
        length: "w-full",
        type: 'text',
        render: <Input />
    },
    {
        title: 'MiddleName',
        name: 'middle_name',
        order: 8,
        visible: true,
        length: "w-full",
        type: 'text',
        render: <Input />
    },
    {
        title: 'LastName',
        name: 'last_name',
        required: true,
        order: 8,
        visible: true,
        length: "w-full",
        type: 'text',
        render: <Input />
    },
    {
        title: 'Company',
        name: 'company_name',
        order: 1,
        visible: true,
        length: "md:w-1/2 lg:w-1/2 sm:w-full",
        type: 'Lookup_text',
        render: (value) => <CompanyLookup formRef={formRef} record={value} />
    },
    // {
    //     title: 'Role',
    //     name: 'role',
    //     order: 8,
    //     visible: true,
    //     length: "md:w-2/3 lg:w-2/3 sm:w-full",
    //     type: 'radio',
    //     required: true,
    //     render: (disabled) => (
    //         <Radio.Group onChange={handleRoleChange} disabled={UserId * 1 === formRef?.getFieldValue('id') * 1 || disabled}>
    //             {RoleKey === '1.0' && <Radio value={1}>Super User</Radio>}
    //             {(RoleKey === '1.0' || RoleKey === '2.1') && <Radio value={2.1}>Account Manager</Radio>}
    //             {(RoleKey === '1.0' || RoleKey === '2.1' || RoleKey === '2.2') &&
    //                 <Radio value={2.2}>Portfolio Manager</Radio>}
    //             {(RoleKey === '1.0' || RoleKey === '2.1' || RoleKey === '2.2' || RoleKey === '3.0') &&
    //                 <Radio value={3}>Company Admin</Radio>}
    //             {(RoleKey === '1.0' || RoleKey === '2.2' || RoleKey === '2.1' || RoleKey === '3.0') &&
    //                 <Radio value={4}>Standard User</Radio>}
    //         </Radio.Group>
    //     )
    // },
    {
        title: 'Role',
        name: 'role',
        order: 8,
        visible: true,
        length: "md:w-2/3 lg:w-2/3 sm:w-full",
        type: 'radio',
        required: true,
        render: (readOnly) => (
            <RolePicker formRef={formRef} readOnly={readOnly} />
        )
    },
    {
        title: 'is Active',
        name: 'is_active',
        order: 8,
        visible: true,
        length: "md:w-2/3 lg:w-2/3 sm:w-full",
        type: 'checkbox',
        required: true,
        render: (disabled) => (<Radio.Group defaultValue={true} disabled={UserId * 1 === formRef?.getFieldValue('id') * 1 || disabled}>
            <Radio value={true}> Active </Radio>
            <Radio value={false}> Disable </Radio>
        </Radio.Group>)
    },
    // {
    //     title: 'User Type',
    //     name: 'user_type',
    //     order: 8,
    //     visible: true,
    //     length: "md:w-2/3 lg:w-2/3 sm:w-full",
    //     type: 'text',
    //     required: true,
    //     tooltip: "Portfolio Manager can only manage facilities that are assigned to them. Account Manager can manage all facilities in the company.",
    //     render:   <Radio.Group defaultValue={3}>
    //         <Radio value={1} >Account Manager</Radio>
    //         <Radio value={2} >Portfolio Manager</Radio>
    //         <Radio value={3} >Client</Radio>
    //     </Radio.Group>
    // },


    // {
    //     title: 'Separator',
    //     name: 'Separator',
    // },
    // custom_text params: {fields, readOnly}
    {
        title: 'Assigning Company and Facility',
        name: 'company',
        order: 1,
        visible: true,
        required: true,
        length: "w-full",
        type: 'custom_text',
        dependent: true,
        dependentFields: ['role', 'companies', 'user'],
        render: (e, readOnly) => {
            return <NewCompanyFacilityPicker formRef={formRef} fields={e} readOnly={readOnly} />
        }
    },

    {
        title: 'Assigned Company',
        name: 'companies',
        order: 1,
        visible: false,
        length: "w-full",
        type: 'text',
    },
    {
        title: 'Facility',
        name: 'user',
        order: 1,
        visible: false,
        length: "w-full",
        type: 'text',
    },

    // {
    //     title: 'Separator',
    //     name: 'Separator',
    // },
    {
        title: 'User Image',
        name: 'image',
        order: 9,
        visible: true,
        length: "sm:w-full",
        type: 'File'
    },

])
