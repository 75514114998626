import { useCallback } from 'react';
import { useSelector } from 'react-redux';

export const useHasPermission = () => {
    const permission = useSelector(state => state.Auth.permission);
    const role = useSelector(state => (state.Auth.role && Object.keys(state.Auth.role)[0] * 1) || 0);

    return useCallback((allowedPermissions) => {

        let allowedPermissionsStr = allowedPermissions.split(':');
        let perm = allowedPermissionsStr[0] * 1; // 0, 1, 2, 3
        let module = allowedPermissionsStr[1]; // electricity, gas, equipment, facility, company, user, project, workorder

        if (module === 'dashboard' || module === 'profile') {
            return true;
        }
        let permitted = false;

        switch (role) {
            case 1:
                permitted = true;
                break;
            case 2.1:
                switch (module) {
                    case 'electricity':
                    case 'gas':
                    case 'equipment':
                    case 'facility':
                    case 'company':
                    case 'user':
                    case 'project':
                    case 'workorder':
                    case 'equipmentCategory':
                    case 'benchmark':
                    case 'functionform':
                    case 'filetagmanage':
                    case 'functiontesting':
                    case 'customreport':
                    case 'notification':
                    case 'tags':
                    case 'filemanage':
                    case 'log':
                        permitted = permission[module] >= perm;
                        break;
                    default:
                        break;
                }
                break;
            case 2.2:
                switch (module) {
                    case 'electricity':
                    case 'gas':
                    case 'equipment':
                    case 'facility':
                    case 'company':
                    case 'user':
                    case 'project':
                    case 'workorder':
                    case 'benchmark':
                    case 'functionform':
                    case 'filetagmanage':
                    case 'functiontesting':
                    case 'customreport':
                    case 'notification':
                    case 'log':
                        permitted = perm === 1;
                        break;
                    default:
                        break;
                }
                break;
            case 3:

                switch (module) {
                    case 'electricity':
                    case 'gas':
                    case 'equipment':
                    case 'facility':
                    case 'user':
                    case 'project':
                    case 'workorder':
                    case 'benchmark':
                    case 'functionform':
                    case 'filetagmanage':
                    case 'functiontesting':
                    case 'customreport':
                    case 'notification':
                    case 'log':
                        permitted = permission[module] >= perm;
                        break;
                    default:
                        break;
                }
                break;
            case 4:
                switch (module) {
                    case 'electricity':
                    case 'gas':
                    case 'equipment':
                    case 'project':
                    case 'workorder':
                    case 'benchmark':
                    case 'functionform':
                    case 'filetagmanage':
                    case 'functiontesting':
                    case 'customreport':
                    case 'log':
                    case 'notification':
                        permitted = permission[module] >= perm;
                        break;
                    default:
                        break;
                }
                break;
            default:
                break;
        }
        return permitted;
    }, [permission, role]);
};