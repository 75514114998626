import React, {Component} from 'react';
import _ from "lodash";
import {Button, Checkbox, Drawer, Form, Input, PageHeader} from "antd";

import {api} from "@redux/api";

import {connect} from "react-redux";

import notification from "antd/es/notification";
import PermissionSettingStyle from "@components/PermissionSetting/PermissionSettingStyle";
import {CopyOutlined} from "@ant-design/icons";
import PermissionControl from "@components/Permission/Permission";

export const permissionOptions = [
    {label: 'Disabled', value: '0'},
    {label: 'Read Only', value: '1'},
    {label: 'Editable', value: '2'},
    {label: 'Full Access', value: '3'},
];

export const roleOptions = [
    {label: 'Super User', value: 1},
    {label: 'Account Manager', value: 2.1},
    {label: 'Portfolio Manager', value: 2.2},
    {label: 'Company Admin', value: 3},
    {label: 'Standard User', value: 4},
];

class userForm extends Component {
    constructor(props) {
        super(props);
        this.permissionformRef = React.createRef();
        this.state = {
            fields: [],
            fileList: [],
            tags: [
                {id: 1, content: 'Record'}
            ],
        }
    }

    componentDidMount() {
        this.setState({
            data: this.props.data
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.data !== nextProps.data) {
            this.setState({
                data: nextProps.data
            })
        }
    }

    handleDelete = (e) => {
        // console.log('details data', this.props.data)
        let id = _.get(this.props.data, 'id')
        return api.deleteUserpermissions(id).then(
            response => {
                notification['success']({
                    message: 'Success',
                    description: 'Record was successfully deleted', placement: 'topLeft'
                });
                this.props.handleDrawOpen(false, null);
                this.props.handleRefresh();
                return response.data
            }
        ).catch(error => (
            error
        ));
    }

    handleClone = (data) => {
        if (data['role']) {
            data['role'] = data['role'][0] * 1;
            let data_new = this.state.data;
            data_new['name'] = data_new['name'] + ' - Copy';
            api.createUserpermissions(data_new).then(
                response => {
                    notification['success']({
                        message: 'Success',
                        description: 'Record was successfully created', placement: 'topLeft'
                    });
                    this.props.handleDrawOpen(false, null);
                    this.props.handleRefresh();
                }
            ).catch(error => (
                error
            ));
        }


    }

    handleSubmit = (data) => {
        data['role'] = data['role'][0] * 1;
        if (data?.id && data?.id !== '0') {
            api.editUserpermissions(data.id, data).then(
                response => {
                    notification['success']({
                        message: 'Success',
                        description: 'Record was successfully saved', placement: 'topLeft'
                    });
                    this.props.handleDrawOpen(false, null);
                    this.props.handleRefresh();
                }
            ).catch(error => (
                error
            ));
        } else {
            return api.createUserpermissions(data).then(
                response => {
                    // console.log('create', response);
                    this.props.handleDrawOpen(false, null);
                    this.props.handleRefresh();
                    return response.data
                }
            ).catch(error => (
                error
            ));
        }

    }

    handleChange = (e, name) => {

        let data = this.state.data;
        let data2 = this.state.data ? this.state.data : [];
        let nameValue = this.permissionformRef.current.getFieldValue('name');
        let commentValue = this.permissionformRef.current.getFieldValue('comment');

        if (data?.id) {
            let old = data[name];
            for (let i = 0; i < e.length; i++) {
                if (old !== e[i] * 1 && e[i] !== 0) {
                    data2[name] = e[i] * 1
                }
            }
        } else {
            if (this.state.data?.hasOwnProperty(name)) {
                let oldKey = this.state.data[name];
                let newKey = e.filter(f => f != oldKey);
                data2 = {...data, [name]: newKey[0] * 1, name: nameValue, comment: commentValue}
            } else {
                data2 = {...data, [name]: e[0] * 1, name: nameValue, comment: commentValue}
            }
        }
        console.log('permissionchanged', data2, e, name)
        this.setState({
            data: data2
        })
    }

    onClose = (e) => {
        this.props.handleDrawOpen(false, null)
    }

    render() {

        let data = this.state.data;

        let formFields = [
            {
                title: 'key',
                name: 'id',
                order: 0,
                visible: false,
                length: 0,
                type: 'text',
            },
            {
                title: 'Name',
                name: 'name',
                order: 1,
                visible: true,
                length: "w-full ",
                type: 'text',
                required: true,
                render: <Input type="text"/>,
            },
            {
                title: 'Comment',
                name: 'comment',
                order: 1,
                visible: true,
                length: "w-full",
                type: 'text',
                render: <Input type="text"/>,
            },
            {
                title: 'Role',
                name: 'role',
                order: 1,
                visible: true,
                length: "w-full",
                type: 'text',
                render: <Checkbox.Group onChange={(e) => this.handleChange(e, 'role')}
                                        options={roleOptions.filter(e => e.value > this.props.Role || this.props.Role === 1)}/>,
            },
            {
                title: 'Electricity',
                name: 'electricity',
                order: 1,
                visible: true,
                length: "w-full",
                type: 'text',
                render: <Checkbox.Group onChange={(e) => this.handleChange(e, 'electricity')}
                                        options={permissionOptions.filter(e => {

                                                // let selected_role = this.permissionformRef?.current?.getFieldValue('role');
                                                // selected_role = selected_role ? selected_role[0] : 0;
                                                // if (selected_role === 2.2 && e.value > 1) {
                                                //     return null
                                                // }

                                                let perm = this.props.Permission?.electricity;
                                                perm = perm?.toString();
                                                if (perm) {
                                                    return <PermissionControl allowedPermissions={perm + ':user'}
                                                                              returnBool={true}>
                                                        {e}
                                                    </PermissionControl>
                                                }
                                            }
                                        )}
                />,
            },

            {
                title: 'Gas',
                name: 'gas',
                order: 1,
                visible: true,
                length: "w-full",
                type: 'text',
                render: <Checkbox.Group onChange={(e) => this.handleChange(e, 'gas')}
                                        options={permissionOptions.filter(e => {

                                            // let selected_role = this.permissionformRef?.current?.getFieldValue('role');
                                            // selected_role = selected_role ? selected_role[0] : 0;
                                            // if (selected_role === 2.2 && e.value > 1) {
                                            //     return null
                                            // }

                                            let perm = this.props.Permission?.gas;
                                            perm = perm?.toString();
                                            if (perm) {
                                                return <PermissionControl allowedPermissions={perm + ':user'}
                                                                          returnBool={true}>
                                                    {e}
                                                </PermissionControl>
                                            }
                                        })}/>,
            },
            {
                title: 'Company',
                name: 'company',
                order: 1,
                visible: this.props.Role <= 2.1,
                length: "w-full",
                type: 'text',
                render: <Checkbox.Group onChange={(e) => this.handleChange(e, 'company')}
                                        options={permissionOptions.filter(e => {
                                            // let selected_role = this.permissionformRef?.current?.getFieldValue('role');
                                            // selected_role = selected_role ? selected_role[0] : 0;
                                            // if (selected_role >= 3 || (selected_role === 2.2 && e.value > 1)) {
                                            //     return null
                                            // }
                                            let perm = this.props.Permission?.company;
                                            perm = perm?.toString();
                                            if (perm) {
                                                return <PermissionControl allowedPermissions={perm + ':user'}
                                                                          returnBool={true}>
                                                    {e}
                                                </PermissionControl>
                                            }
                                        })}
                />,
            },
            {
                title: 'Facility',
                name: 'facility',
                order: 1,
                visible: this.props.Role <= 2.1 || this.props.Role === 3,
                length: "w-full",
                type: 'text',
                render: <Checkbox.Group onChange={(e) => this.handleChange(e, 'facility')}
                                        options={permissionOptions.filter(e => {
                                            // let selected_role = this.permissionformRef?.current?.getFieldValue('role');
                                            // selected_role = selected_role ? selected_role[0] : 0;
                                            // if (selected_role === 4 || (selected_role === 2.2 && e.value > 1)) {
                                            //     return null
                                            // }
                                            let perm = this.props.Permission?.facility;
                                            perm = perm?.toString();
                                            if (perm) {
                                                return <PermissionControl allowedPermissions={perm + ':user'}
                                                                          returnBool={true}>
                                                    {e}
                                                </PermissionControl>
                                            }
                                        })}/>,
            },
            {
                title: 'Equipment',
                name: 'equipment',
                order: 1,
                visible: true,
                length: "w-full",
                type: 'text',
                render: <Checkbox.Group onChange={(e) => this.handleChange(e, 'equipment')}
                                        options={permissionOptions.filter(e => {

                                            // let selected_role = this.permissionformRef?.current?.getFieldValue('role');
                                            // selected_role = selected_role ? selected_role[0] : 0;
                                            // if (selected_role === 2.2 && e.value > 1) {
                                            //     return null
                                            // }

                                            let perm = this.props.Permission?.equipment;
                                            perm = perm?.toString();
                                            if (perm) {
                                                return <PermissionControl allowedPermissions={perm + ':user'}
                                                                          returnBool={true}>
                                                    {e}
                                                </PermissionControl>
                                            }
                                        })}/>,
            },
            {
                title: 'Workorder',
                name: 'workorder',
                order: 1,
                visible: true,
                length: "w-full",
                type: 'text',
                render: <Checkbox.Group onChange={(e) => this.handleChange(e, 'workorder')}
                                        options={permissionOptions.filter(e => {

                                            // let selected_role = this.permissionformRef?.current?.getFieldValue('role');
                                            // selected_role = selected_role ? selected_role[0] : 0;
                                            // if (selected_role === 2.2 && e.value > 1) {
                                            //     return null
                                            // }

                                            let perm = this.props.Permission?.workorder;
                                            perm = perm?.toString();
                                            if (perm) {
                                                return <PermissionControl allowedPermissions={perm + ':user'}
                                                                          returnBool={true}>
                                                    {e}
                                                </PermissionControl>
                                            }
                                        })}/>,
            },
            {
                title: 'EquipmentCategory',
                name: 'equipmentCategory',
                order: 1,
                visible: this.props.Role === 1,
                length: "w-full",
                type: 'text',
                render: <Checkbox.Group onChange={(e) => this.handleChange(e, 'equipmentCategory')}
                                        options={permissionOptions.filter(e => {
                                            // let selected_role = this.permissionformRef?.current?.getFieldValue('role');
                                            // selected_role = selected_role ? selected_role[0] : 0;
                                            // if (selected_role !== 1) {
                                            //     return null
                                            // }
                                            let perm = this.props.Permission?.equipmentCategory;
                                            perm = perm?.toString();
                                            if (perm) {
                                                return <PermissionControl allowedPermissions={perm + ':user'}
                                                                          returnBool={true}>
                                                    {e}
                                                </PermissionControl>
                                            }
                                        })}/>,
            },
            {
                title: 'User',
                name: 'user',
                order: 1,
                visible: this.props.Role <= 3 && this.props.Role !== 2.2,
                length: "w-full",
                type: 'text',
                render: <Checkbox.Group onChange={(e) => this.handleChange(e, 'user')}
                                        options={permissionOptions.filter(e => {

                                            // let perm = this.props.Permission?.user;
                                            // perm = perm?.toString();
                                            // let selected_role = this.permissionformRef?.current?.getFieldValue('role');
                                            // selected_role = selected_role ? selected_role[0] : 0;
                                            // if (selected_role === 4 || (selected_role === 2.2 && e.value > 1)) {
                                            //     perm = false;
                                            // }
                                            let perm = this.props.Permission?.user;
                                            perm = perm?.toString();
                                            if (perm) {
                                                return <PermissionControl allowedPermissions={perm + ':user'}
                                                                          returnBool={true}>
                                                    {e}
                                                </PermissionControl>
                                            }
                                        })}/>,
            },
            // {
            //     title: 'Project',
            //     name: 'project',
            //     order: 1,
            //     visible: true,
            //     length: "w-full",
            //     type: 'text',
            //     render: <Checkbox.Group onChange={(e) => this.handleChange(e, 'project')} options={permissionOptions}/>,
            //     hidden: !perm['Project']
            // },
            {
                title: 'Benchmark',
                name: 'benchmark',
                order: 1,
                visible: true,
                length: "w-full",
                type: 'text',
                render: <Checkbox.Group onChange={(e) => this.handleChange(e, 'benchmark')}
                                        options={permissionOptions.filter(e => {
                                            let perm = this.props.Permission?.benchmark;
                                            perm = perm?.toString();
                                            if (perm) {
                                                return <PermissionControl allowedPermissions={perm + ':user'}
                                                                          returnBool={true}>
                                                    {e}
                                                </PermissionControl>
                                            }
                                        })}/>,
            },
            {
                title: 'CustomReport',
                name: 'customreport',
                order: 1,
                visible: true,
                length: "w-full",
                type: 'text',
                render: <Checkbox.Group onChange={(e) => this.handleChange(e, 'customreport')}
                                        options={permissionOptions.filter(e => {
                                            let perm = this.props.Permission?.customreport;
                                            perm = perm?.toString();
                                            if (perm) {
                                                return <PermissionControl allowedPermissions={perm + ':user'}
                                                                          returnBool={true}>
                                                    {e}
                                                </PermissionControl>
                                            }
                                        })}/>,
            },
            {
                title: 'Filemanage',
                name: 'filemanage',
                order: 1,
                visible: true,
                length: "w-full",
                type: 'text',
                render: <Checkbox.Group onChange={(e) => this.handleChange(e, 'filemanage')}
                                        options={permissionOptions.filter(e => {
                                            let perm = this.props.Permission?.filemanage;
                                            perm = perm?.toString();
                                            if (perm) {
                                                return <PermissionControl allowedPermissions={perm + ':user'}
                                                                          returnBool={true}>
                                                    {e}
                                                </PermissionControl>
                                            }
                                        })}/>,
            },
            {
                title: 'Tags',
                name: 'tags',
                order: 1,
                visible: true,
                length: "w-full",
                type: 'text',
                render: <Checkbox.Group onChange={(e) => this.handleChange(e, 'tags')}
                                        options={permissionOptions.filter(e => {
                                            let perm = this.props.Permission?.tags;
                                            perm = perm?.toString();
                                            if (perm) {
                                                return <PermissionControl allowedPermissions={perm + ':user'}
                                                                          returnBool={true}>
                                                    {e}
                                                </PermissionControl>
                                            }
                                        })}/>,
            },
            {
                title: 'Functional Form',
                name: 'functionform',
                order: 1,
                visible: true,
                length: "w-full",
                type: 'text',
                render: <Checkbox.Group onChange={(e) => this.handleChange(e, 'functionform')}
                                        options={permissionOptions.filter(e => {
                                            let perm = this.props.Permission?.functionform;
                                            perm = perm?.toString();
                                            if (perm) {
                                                return <PermissionControl allowedPermissions={perm + ':user'}
                                                                          returnBool={true}>
                                                    {e}
                                                </PermissionControl>
                                            }
                                        })}/>,
            },
            {
                title: 'Functional Testing',
                name: 'functiontesting',
                order: 1,
                visible: true,
                length: "w-full",
                type: 'text',
                render: <Checkbox.Group onChange={(e) => this.handleChange(e, 'functiontesting')}
                                        options={permissionOptions.filter(e => {
                                            let perm = this.props.Permission?.functiontesting;
                                            perm = perm?.toString();
                                            if (perm) {
                                                return <PermissionControl allowedPermissions={perm + ':user'}
                                                                          returnBool={true}>
                                                    {e}
                                                </PermissionControl>
                                            }
                                        })}/>,
            },
            {
                title: 'Log',
                name: 'log',
                order: 1,
                visible: true,
                length: "w-full",
                type: 'text',
                render: <Checkbox.Group onChange={(e) => this.handleChange(e, 'log')}
                                        options={permissionOptions.filter(e => {
                                            let perm = this.props.Permission?.log;
                                            perm = perm?.toString();
                                            if (perm) {
                                                return <PermissionControl allowedPermissions={perm + ':user'}
                                                                          returnBool={true}>
                                                    {e}
                                                </PermissionControl>
                                            }
                                        })}/>,
            },
        ]

        // console.log('data', data)
        let fields = [];
        if (formFields) {
            formFields.forEach(e => {
                    if (e.name === 'role') {
                        let field = e
                        field['value'] = data ? [data[e.name]] : null;
                        fields.push(field)
                    } else {
                        let field = e
                        field['value'] = data ? data[e.name]?.toString() : '';
                        fields.push(field)
                    }
                }
            )
        }

        console.log('fields', fields)
        return (
            <Drawer
                width={'60%'}
                placement="right"
                closable={false}
                onClose={this.onClose}
                visible={this.props.isDrawOpen}
                destroyOnClose={true}
                autoFocus={true}
                maskClosable={true}
            >
                <PermissionSettingStyle>
                    <div className={'slds-m-around_x-small'}>
                        <PageHeader
                            title={'Access Objects'}
                            className="site-page-header"

                            extra={[
                                <Button type='' key="Close" onClick={this.onClose}>Close</Button>,
                                <Button type='' danger key="Delete" onClick={this.handleDelete}>Delete</Button>,
                                <Button type='primary' warning key="3" form="permissionForm" htmlType="submit"
                                        onClick={this.handleClone} icon={<CopyOutlined/>}>Clone</Button>,
                                <Button type='primary' key="submit" form="permissionForm"
                                        htmlType="submit">Save</Button>
                            ]}

                        />
                    </div>
                    <div className={'slds-m-around_x-small'}>
                        <Form
                            onFinish={this.handleSubmit}
                            layout="horizontal"
                            fields={fields}
                            ref={this.permissionformRef}
                            id="permissionForm"
                        >

                            {fields.map(e => {
                                    return (
                                        <Form.Item label={e.title} name={e.name} className={e.length}
                                                   rules={[
                                                       {
                                                           required: e.required,
                                                           message: 'this field is required',
                                                       },
                                                   ]}
                                                   hidden={!e.visible}>
                                            {e.render}
                                        </Form.Item>
                                    )
                                }
                            )}
                        </Form>
                    </div>
                </PermissionSettingStyle>
            </Drawer>

        );
    }
}

const mapStateToProps = state => {
    return {
        // user: _.get(state.user, 'data'),
        // SelectedFacility: _.get(state.Facility, 'selected.Id'),
        Role: _.get(state, 'Auth.role') ? Object.keys(_.get(state, 'Auth.role'))[0] * 1 : null,
        Permission: state.Auth.permission
    }
}
export default connect(
    mapStateToProps,
    null
)(userForm);

