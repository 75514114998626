export const getFormValuesByFormRef = (formRef) => {
    if (typeof formRef?.getFieldsValue === 'function') {
        const values = formRef.getFieldsValue();
        return values

    } else if (typeof formRef?.current?.getFieldsValue === 'function') {
        const values = formRef.current.getFieldsValue();
        return values
    }
    return {};
}